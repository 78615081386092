.like-pro-feed {
    border-bottom: $section-break;
    >.container {
      text-align: center;
      .row {
        padding:20px 0;
        @media screen and (min-width:$tablet-small){
          padding:40px 0 20px;
        }
      }
      &:nth-child(2){
        @media screen and (max-width:$mobile){
          padding: 0;
        }
      }
    }
  }
  .pros {
    @media screen and (min-width:$tablet-small){
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 50px;
      padding-bottom: 50px;
    }
    .pro {
      width: 100%;
      a {
        color: $color-black;
        text-decoration: none;
        &:hover {
          .pagetitle {
            text-decoration: underline;
          }
        }
      }
      img {
        display: block;
        width: 100%;
      }
      .content {
        position:relative;
        margin-top: -50px;
        background-color: hsla(0,0%,100%,.85);
        @supports ((-webkit-backdrop-filter: blur(2em)) or (backdrop-filter: blur(2em))){
          backdrop-filter: blur(27px);
          background-color: transparent;
          -webkit-backdrop-filter: blur(27px);
        }
        text-align: left;
        padding:40px 20px 20px;
        @media screen and (max-width: $mobile){
          padding-left: 10px;
          padding-right: 10px;
          margin-left: 0px;
          margin-right: 0px;
        }
      }
      .pagetitle {
        font-family: $font-subheading;
        font-size: 18px;
      }
      .link {
        font-family: $font-subheading;
        display: inline-block;
        &::after {
          content: '';
          border-bottom: $section-break;
          display: block;
        }
      }
    }
  }