.draggable {
  border-bottom: 10px solid $color-primary;
  .mobile-info-block {
    @include media-breakpoint-up(md) {
      display: none;
    }
  }
  .swiper-container {
    padding-right: 100px;
    height: 700px;
    .swiper-wrapper {
      height: 700px;
      transition: $trans;
      background-color: $color-black;

      .swiper-slide {
        display: flex;
        transition: $trans;
        background-position: 50% 50%;
        background-repeat: no-repeat;
      }
      .athlete-slide {
        @include media-breakpoint-down(sm) {

        }
        .overlay {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          background-color: black;
          opacity: 0.2;
          z-index: 0;
        }
      }
      .draggable-info {
        @include media-breakpoint-down(sm) {
          display: none;
        }
        min-width: 25rem !important;
        @include media-breakpoint-up(md) {
          width: 35rem !important;
        }
        @include media-breakpoint-up(lg) {
          width: 35rem !important;
        }

        height: 100%;
        background-color: $color-white;
        .athlete-slide {
          width: 32rem;
        }
      }
      .athlete-slide {
        .info-block {
          margin: 0 auto;
          z-index: 99;
          padding: 0;
          .subheading {
            font-size: 2vw;

            @media screen and (max-width: $mobile) {
              font-size: 17px;
            }
          }
          .section-title {
          }
          a {
            margin-top: 2rem;
            font-size: 1.5rem;
            &:hover {
              text-decoration: underline;
            }
          }
        }
        &:last-child {
          padding-right: 100px;
        }
      }
    }
  }

  .info-block {
    font-family: $font-heading;
    text-transform: uppercase;
    padding: 0 40px;

    @include media-breakpoint-up(md) {
      padding: 0 20px;
    }

    .subheading {
      font-size: 15px;
      width: 100%;
      padding-bottom: 2rem;
      color: $color-primary;
      }
      .section-title {
        width: 100%;
      }
      p.paragraph {
        font-family: $font-body;
        text-transform: none;
        font-size: 16px;
        line-height: 1.4;
        @include media-breakpoint-up(md) {
          font-size: 1.8rem;
        }
        @include media-breakpoint-up(lg) {
          line-height: 1.6667;
        }
      }
    }
}

.draggable-article .explore-boats { 
  .boat-cat {
    @media screen and (max-width: $mobile-small) {
      padding: 0 10px;
    }
    .boat-cat-byline {
      @media screen and (max-width: $mobile-small) {
        font-size: 17px;
      }
    }
  }
}