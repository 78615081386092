.hero-carousel {
  overflow: hidden;
  position: relative;
  margin-bottom: -1px;
  .swiper-wrapper {
    z-index: auto;
  }
  .hero-swiper-container {
    //margin-bottom: 10px;
    height: 45vw;
    min-height: 400px;
    @include transition(250);
    @media screen and (max-width: $tablet-small) {
      height: 75vw;
      margin-bottom: 50px;
    }
    @media screen and (max-width: $mobile-small) {
      height: 125vw;
    }
    .video-header {
      height: 100%;
      width: 100%;
      overflow: hidden;
      font-family: $font-heading !important;
      .overlay {
        // position: absolute;
        // top: 0;
        // left: 0;
        // height: 100%;
        // width: 100%;
        // background-color: $color-white;
        // opacity: 0.25;
        // z-index: 1;
      }
      video,img {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        object-fit: cover;
      }
      .inner-content {
        position: relative;
        z-index: 20;
        width: 100%;
        padding-top:250px;
        @media screen and (max-width: $tablet-small) {
          padding-top: 120px;
        }
        .hero-text-wrapper {
            align-items: flex-start;
          .hero-text {
            height: 100%;
            max-width: 1610px;
            margin: 0 auto;
            text-align: left;
            padding:0 20px;
            position: relative;
            @media screen and (max-width: $tablet-small) {
              padding: 0 60px;
            }
            &::after {
              // content: '';
              // display: block;
              // background: $color-white;
              // width:167px;
              // height: 110px;
              // position:absolute;
              // bottom: 0;
              // left: 20px;
              //z-index: 2;
            }
            .subheading {
              font-size: 20px;
              font-family: $font-subheading;
              text-transform: uppercase;
              letter-spacing: 0;
              max-width: 740px;
            }
            .container-sm{
              width: 100%;
              max-width: 1024px;
              margin: auto;
            }
          }
        }
      }
      .overlay {
        // position: absolute;
        // top: 0;
        // left: 0;
        // height: 100%;
        // width: 100%;
        // background-color: $color-white;
        // opacity: 0.25;
        // z-index: 1;
      }
    }
  }
  .hero-slider-bg {
    min-width: 164px;
    position:absolute;
    bottom: 0;
    left: 20px;
    z-index: 100;
    background-color: $color-white;
    padding:40px 38px 50px;
    text-align: center;
    @media screen and (max-width: $tablet-small) {
      padding:22px 18px 20px;
      left: 50%;
      transform: translate(-50%);
      &:empty {
        background-color: transparent;
      }
    }
    &.disabled {
      background-color: transparent;
    }
  }
  a.section-link {
    @include ctaButton(19px);
  }
  .pagination-wrapper {
    width: 100%;
    position:absolute;
    bottom: 0;
    text-align: center;
  }
  .pagination-width {
    margin: 0 auto;
    width: 100%;
    max-width: 1570px;
    text-align: left;
    padding: 0 0 0 20px;
    @media screen and (min-width: 1556px) {
      padding-left:0px;
    }
    @media screen and (max-width: $tablet-small) {
      padding: 0;
      text-align: center;
    }
  }
  .swiper-pagination {
    min-width: 210px;
    padding:20px 40px 0;
    @media screen and (max-width: $tablet-small) {
      min-width: 170px;
      padding:20px 0 0 0;
    }
    height: 50px;
    width: auto;
    display: inline-block;

    &.disabled, &.swiper-pagination-lock {
      display: none;
    }
  }

  .swiper-button-prev {
    @media screen and (min-width: $tablet-small) {
      left: 4%;
    }
    &.disabled {
      display: none;
    }
  }
  .swiper-button-next {
    @media screen and (min-width: $tablet-small) {
      right: 4%;
    }
    &.disabled {
      display: none;
    }
  }
  .carousel-indicators {
    position: relative;
    margin-top: 0;
    margin-bottom: 0;

    li {
      width: 68px;
      margin: 0 16px;

      @media screen and (max-width: $mobile) {
        width: 34px;
        margin: 0 4px;
      }
    }
  }

  // Short hero styles
  &.short {
    .hero-swiper-container {
      height: 26.315vw;
      @media screen and (max-width: $tablet-small) {
        height: 56.25vw;
      }
      @media screen and (max-width: $mobile-small) {
        height: 75vw;
      }
      .video-header {
        .inner-content {
          padding-top: 60px;
          .hero-text {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
          }
        }
      }
    }
  }
  &.White .hero-swiper-container .video-header .inner-content .hero-text{
    h1.page-title, h3.subheading {
      color: $color-white;
    }
  }
}
// @media screen and (min-width: $tablet) {
//   #hero-cinematic-carousel + .text-block {
//     &>.container {
//       margin-top: -100px;
//       padding-top: 50px;
//     }
//     &.white>.container {
//       background-color: $color-white;
//     }
//     &.red>.container {
//       background-color: $color-primary;
//     }
//   }
// }