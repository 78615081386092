.block_50-50 {
  border-bottom: $section-break;

  &:nth-child(n+1){
    padding-top: 44px;
    padding-bottom: 60px;

    @media screen and (max-width:$mobile) {
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }

  &:nth-child(odd) {
    .image_50-50 {
      @media screen and (min-width:$mobile) {
        order: 2;
      }

    }
  }

  .theme-background {
     padding: 0rem 0;
  }

  .content_50-50 {
    .copy-block-semi {
      margin-bottom: 20px;
      padding: 3rem;

      @media screen and (max-width:$mobile){
        padding: 0;
        margin: 15px auto;
      }

      .section-title {
        font-size: 36px;
        @media screen and (max-width:$mobile){
          font-size: 32px;
          margin-bottom: 5px;
        }
        &:after {
          height: 5px;

          @media screen and (max-width:$mobile){
            content: none;
          }
        }
      }

      .pagetitle{
        font-family: "AtlasTypewriter-Bold";
        font-size: 28px;
        @media screen and (max-width:$mobile){
          font-size: 16px;
          content: none;
        }
      }

      .copy {
        font-size: 18px;
        @media screen and (max-width: $mobile) {
          font-size: 16px;
        }
      }
      .sub-title {
          padding-bottom: 2rem;
          color: $color-primary;
      }
      .title::after {
          width: 4rem;
      }
    }
  }
  .image_50-50 {
    padding-bottom: 40px;
    overflow: hidden;
    @media screen and (max-width:$small){
      padding-top: 0;
      padding-bottom: 0;
    }

    .image_link {
      height: 100%;
      width: 100%;
      display: block;
    }

    figure {
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: center;
      margin: 0;
      position: relative;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }

      .play-button {
        width: 5rem;
        height: 5rem;
        transform: none;
        &:before {
          left: 57%;
          border-top: 1rem solid transparent;
          border-left: 1.5rem solid #fff;
        }
      }

      &::before {
          content: '';
          display: block;
          padding-top: 66.6666666667%;
          @media screen and (min-width: $mobile-small) {
            padding-top: 400px;
          }
      }
      &.image-fit {
        background-size: contain;
      }
      &.image-cover {
        background-size: cover;
      }
    }
  }

  .modal {
    .modal-dialog {
      max-width: 100%;
      @include media-breakpoint-up(md) {
        max-width: 1050px;
    	}
    }
  }
}

// Load More Content Nest 50/50 block styles
.component-block_block-load-more-content {
  .block_50-50 {
    .content_50-50 {
      .section-title {
        font-size: 25px;

        @media screen and (max-width:$mobile){
          font-size: 17px;
        }
      }
      .subheading {
        color: $color-primary;
        @media screen and (max-width: $mobile) {
          font-size: 17px;
        }
      }
    }
  }
}
