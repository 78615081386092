.social-media {
    padding-left: 0;
    li {
        margin: 0 1.3rem;
        
        a {
            width: 1rem;
            max-width: 27px;

            @media screen and (max-width:$mobile) {
                max-width: 19px;
            }
            &.icon-facebook {
                max-width: 15px;

                @media screen and (max-width:$mobile) {
                    max-width: 10px;
                }
            }
            svg {
                height: 100%;
                width: 100%;
                //fill: #d7dfe3;
            }
        }
    }
    @media screen and (max-width:$mobile) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
}