.default-template-main {
  #hero {
    height: 500px;
    width: 100%;
    background-size: cover;
    background-color: transparent;
    background-repeat: no-repeat;
  }
}

.hero-text {
  .sub-heading {
    font-size: 40px;
    @media screen and (max-width: $mobile) {
      font-size: 20px;
    }
  }
}

h1.section-title, h1.page-title {
  @include h1;
}

.page-title, .section-title {
  margin:0 0 17px 0;
  padding: 0;
  font-family: $font-heading;
  width: 100%;
  line-height: 1;
  @include h1;
  @include transition(250);

  &.ready {
    opacity: 1;
  }
  &.loaded {
    &::after {
      width: 75px;
    }
  }

  @media (max-width: $mobile) {
    margin-bottom: 10px;
  }
}

.section-heading {
  h3, p {
    text-align: center;
  }
}

.sub-heading {
  backface-visibility: hidden;
}
.container-sm {
  max-width: $container_sm;
  padding: 0 30px;
  margin: 0 auto;
  @media screen and (max-width: $mobile) {
    padding: 0 5%;
  }
}

.quote-section {
  border-bottom: $section-break;
}