.imageQuotePost {
  padding-bottom: 6rem;
  &:not(:first-child) {
    margin-top: 12rem;
  }
  .row {
    .image {

    }
    .quote {
      padding-top: 30px;
      .heading {
        font-family: $font-heading;
        font-size: 4rem;
        line-height: 1.2;
      }
      .author {
        margin-top: 4rem;
        color: $color-primary;
        font-size: 1.5rem;
        text-transform: uppercase;
        font-family: $font-heading;
        .name {

        }
        .seperator {
          margin: 0 10px;
          color: $color-black;
        }
        .group {

        }
      }
    }
  }
}
