.video-slider {
  background-color: $color-black;
  padding-top: 40px;
  border-bottom: $section-break;
  .section-title {
    font-family: $font-heading;
    font-size: 40px;
  }
  .section-sub {
    text-align: center;
    font-family: $font-body;
    font-size: 25px;
    margin-bottom: 40px;
  }
  .video-slider-container {
    .swiper-wrapper {
      .video-slider-content {
        min-height: 600px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 50%;
        img {
          width: 100%;
        }
        .play-button-div {
          width: 100%;
          text-align: center;
          margin: 0 auto;
          position: absolute;
          display: table-row;
          transform: translate(-47%, -50%);
          top: 50%;
          left: 50%;
        }
        .slide-text {
          position: absolute;
          bottom: 20px;
          left: 40px;
          .video-title {
            font-weight: 500;
            font-family: $font-heading;
            font-size: 25px;
            text-transform: uppercase;
          }
          .video-subheading {
            font-size: 25px;
            font-family: $font-heading;
          }
          .explore {
            font-family: $font-heading;
            font-size: 20px;
            text-transform: uppercase;
          }
        }
      }
    }
  }
}
