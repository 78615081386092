/*************************************************************
   * GENERAL
*************************************************************/

// fonts and headings are set in "_fonts.scss"

* {
  box-sizing: border-box;
}

html,
body {
  @include default-font;
  font-family: $font-body;
  background-color: $color-white;
  position: relative;
  z-index: 0;
  overflow-x: hidden;
  overflow-y: auto;

  .barba-container {
    background-color: $color-white;
    position: relative;
  }
}

.section-title {
  cursor: auto;
}

.level-1.parent {
  cursor: pointer;
}

.nav-tab-link {
  cursor: pointer;
}

.theme-dark {
  background-color: $color-primary;
  color: $color-white;

  .swiper-button-prev,
  .swiper-button-next,
  .nav-tabs .nav-tab-item .nav-tab-link {
    color: $color-white;
  }

  .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
    border-color: $color-white;
  }
}

.theme-light {

  .swiper-button-prev,
  .swiper-button-next {
    color: $color-black;
  }

  .nav-tabs .nav-tab-item .nav-tab-link {
    color: $color-gray;
  }
}

.container {
  position: relative;
  max-width: $container;
  padding: 0 $site_container_padding;
  margin: 0 auto;

  @media (max-width: $mobile) {
    max-width: $container_mobile;
    padding: 0 $site_container_padding_mobile;
  }

  @media screen and (max-width: $mobile-small) {
    padding: 0 10px;
  }
}

.skip-link {
  position: absolute;
  top: -50px;
}

/********************************
	* UTILITY
********************************/
.clearfix {
  @include clearfix();
}

.accessibility,
.sr_only {
  @include sr_only();
}

.scroll--lock {
  overflow: hidden;
}

/********************************
	* LAYOUT
********************************/
// disable tel links on desktop
a[href^="tel:"] {
  @media (min-width: $tablet) {
    pointer-events: none;
  }
}

.background-cover {
  @include background-size("cover");
}

.background-contain {
  @include background-size("contain");
}

.bullet-list {
  list-style: outside disc;
  padding-left: 30px;
}

.number-list {
  list-style: outside decimal;
  padding-left: 30px;
  margin: 0;
}

.play-button {
  border-radius: 100%;
  background-color: $color-primary;
  border: 3px solid $color-primary;
  height: 60px;
  width: 60px;
  z-index: 1;
  overflow: visible;
  cursor: pointer;
  display: block;
  margin: 0 auto;
  position: relative;
  transition: all 0.2s ease-in-out;

  .square {
    display: block;
    clip-path: polygon(0 0, 0% 100%, 100% 50%);
    background-color: white;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-40%, -50%);
    transition: all 0.2s ease-in-out;
  }

  &:hover {
    border-color: $color-black;
    background-color: white;

    .square {
      background-color: $color-black;
    }
  }
}

/* Responsive video embed */
.modal {
  z-index: 1050;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  visibility: hidden;
  width: 100%;
  height: 100%;
  overflow: auto;
  pointer-events: none;
  transition: background 1s ease;
  background: rgba(0, 0, 0, 0);
  opacity: 0;
}

.modal-darken {
  visibility: visible;
  pointer-events: auto;
  opacity: 1;
  background: rgba(0, 0, 0, 0.75);
}

.modal .modal-dialog {
  margin: auto;
  @include transition(250);
  z-index: 1060;
  position: absolute;
  left: 50%;
  top: 25%;
  transform: translate(-50%, -50%);
  opacity: 0;

  .modal-body {
    padding: 0;
    overflow: hidden;
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .modal-header {
    position: absolute;
    width: 100%;
    z-index: 1;

    .close {
      width: 48px;
      display: block;
    }
  }

  &.active {
    top: 50%;
    opacity: 1;
  }
}

.video-container {
  opacity: 1;
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.mp4-overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #ccc;
  z-index: 999;

  video {
    width: 100%;
    max-height: 100%;
    box-sizing: border-box;
  }
}

/* Buttons */
a:not([href]):not([tabindex]) {
  &.btn {
    color: $color-white;

    &:hover {
      background-color: transparent !important;
      color: $color-primary !important;
    }
  }
}

.btn-submit,
.btn,
.btn {
  @include ctaButton(22px);
  padding: 0.13rem 3rem 0.03rem;
}

/* global tab styles */

.nav-tabs {
  justify-content: left;
  border: none;

  .nav-tab-item {
    padding: 0.5rem;
    display: flex;
    align-items: center;

    .nav-tab-link {
      padding: 0;
      font-size: 17px;
      font-family: $font-subheading;
      text-decoration: none;
      color: $color-gray;
      transition: $trans;
      line-height: 1.3;
      border: 1px solid transparent;
      // left: 100%;
      // opacity: 0;
      position: relative;

      @media screen and (max-width: $mobile) {
        font-size: 17px;
      }

      @media screen and (max-width: $mobile-small) {
        font-size: 12px;
      }

      span.underline {
        border-bottom: 2px solid transparent !important;
        padding: 0 2.5rem 20px 0;

        @media screen and (max-width: $mobile) {
          padding: 0 20px 0 10px 0;
        }

        display: inline-block;
      }

      &.active {
        color: $color-black;
        background-color: transparent;

        span.underline {
          border-bottom: 2px solid $color-black !important;
        }
      }

      &:hover,
      &:focus {
        border: 1px solid transparent;
      }
    }
  }
}

/* Modal settings */

.modal-backdrop {
  &.show {
    opacity: 0.75;
  }
}

/* Import from prev. site */
.button {
  @extend .btn;
}

.buttons-centered {
  color: $color-primary;
  text-align: center;

  &.center-items {
    padding-bottom: 1rem;
  }
}

.component-button-submit {
  margin: 0.75rem;
  height: auto;
  padding: 2rem;
  @extend .btn-submit;

  &.large {
    font: 1.5rem/1.333 Forza-Bold;
  }
}

section.find-dealer {
  border-bottom: $section-break;
  padding-bottom: 40px;
  background-color: $color-primary;

  h3 {
    color: #fff;
  }

  .btn {
    @include ctaButtonWhite(19px);
  }
}

/* 50/50 Content */
.content_50-50 {
  .copy {
    margin-bottom: 45px;
  }
}

.swiper-button-prev,
.swiper-button-next {
  outline: none;
}

.swiper-button-prev {
  @media screen and (max-width: $tablet-small) {
    left: 0;
  }
}

.swiper-button-next {
  @media screen and (max-width: $tablet-small) {
    right: 0;
  }
}

.disable-scroll {
  width: 100%;
  overflow: hidden;
  position: fixed;
}

.grecaptcha-badge {
  z-index: 20;
}

.swiper-pagination {
  position: relative;
  height: 44px;
  z-index: 1;

  @media screen and (max-width: $mobile) {}

  .swiper-pagination-bullet {
    height: 15px;
    width: 15px;
    background-color: $color-white;
    border-radius: 0;
    border: 2px solid $color-primary;
    //margin: 20px 10px;
    margin: 0 7px;
    opacity: 1;

    @media screen and (max-width: $mobile) {
      margin: 0 4px;
    }

    &.swiper-pagination-bullet-active {
      background-color: $color-primary;
    }
  }
}

.swiper-button-prev {
  left: 10px;
  color: $color-white;

  @media screen and (max-width: $large-screens) {
    left: 1%;
  }

  @media screen and (max-width: $tablet) {
    left: 1vw;
  }

  @media screen and (max-width: $tablet-small) {
    left: 0;
  }

  @media screen and (max-width: $mobile) {
    left: 12px;
  }
}

.swiper-button-next {
  right: 10px;
  color: $color-white;

  @media screen and (max-width: $large-screens) {
    right: 1%;
  }

  @media screen and (max-width: $tablet) {
    right: 1vw;
  }

  @media screen and (max-width: $tablet-small) {
    right: 0;
  }

  @media screen and (max-width: $mobile) {
    right: 12px;
  }
}

.privacy-policy-check-wrapper {
  width: 100%;
  padding: 10px 0;

  .privacy-policy-check {
    display: flex;
    padding: 0 20px;
    max-width: 1236px;
    margin: 0 auto;
    position: relative;
    text-align: left;

    input {
      left: 0px;
    }

    .form-check-label {
      font-size: 12px;
      align-items: flex-start;
      color: $color-gray;
    }
  }
}

.col-12:empty {
  display: none;
}

@media screen and (min-width: $tablet-small) {
  .container.km-pp {
    padding: 0 20px;
  }
}

#hero-cinematic-carousel+.text-block {
  @media screen and (max-width: $tablet-small) {
    margin-top: -50px;
  }
}

.grecaptcha-badge,
.rc-anchor {
  visibility: hidden;
}

.swiper-button-next {
  width: 48px;
  height: 48px;
}

.swiper-button-prev {
  width: 48px;
  height: 48px;
}