/********************************
	HOMEPAGE
********************************/

// begin homepage styles here



@media (pointer: coarse) and (hover: none) {
  .header-video {
    background: url('https://source.unsplash.com/XT5OInaElMw/1600x900') black no-repeat center center scroll;
  }
  .header-video video {
    display: none;
  }
}
