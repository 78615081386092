.faq {
  max-width: 1200px;
  margin: 0 auto;
  padding: 60px 20px;
  .row {
    .faq-section {
      padding: 20px 0;
      .question {
        font-family: $font-heading;
        font-size: 2.2rem;
      }
      .answer {
        font-family: $font-body;
        font-size: 1.8rem;
      }
    }

  }
}
