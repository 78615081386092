.heroImageSemi {
  min-height: 250px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  position: relative;
  border-bottom: $section-break;
  padding-top: 20px;
  padding-bottom: 20px;
  
  @media screen and (min-width: $mobile-small) {
    min-height: 500px;
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    background-color: white;
    opacity: .5;
  }
  .inner-content {
    margin: 0 auto;
    width: 100%;
    max-width: $container_sm;
    z-index: 10;
    padding: 0 15px;
    @media screen and (min-width: $mobile-small) {
      padding: 0 30px;
    }
    .hero-text {
      // display: flex;
      // flex-flow: column;
      // flex-direction:column-reverse;
      .sub-heading {
        display: none;
        @media screen and (min-width: $mobile-small) {
        display: block;
        max-width: 740px;
        font-size: 20px;
        font-family: $font-subheading;
        text-transform: uppercase;
        }
      }
    }
  }
}
