.explore-boats {
  padding:40px 0 20px;
  border-bottom: $section-break;
  @media screen and (min-width:$tablet){
    .swiper-wrapper {
      height: auto;
    }
    .swiper-slide {
      width: auto;
      height: auto;
    }
  }

  .cat-title {
    font-family:$font-subheading;
    font-size: 17px;
    display: inline-block;
    &:after {
      content: '';
      display: block;
      border-bottom: $section-break;
    }
  }
  .cat-description {
    display: none;
    @media screen and (min-width:$tablet-small){
      display: block;
      max-width: 750px;
      margin-left:0;
    }
  }
  .boat-row {
    text-align: center;
    overflow: hidden;
    .boat{
      margin: auto;
      padding-bottom: 40px;
      max-width: 400px;
      text-align: left;
      @media screen and (max-width: $mobile-small) {
        max-width: unset;
      }
      a:not(.boat-btn):not(.compare-btn){
        display: block;
        width: 100%;
      }
      img {
        display: block;
        width: 100%;
        margin: 0 auto;
      }
      .tag {
        font-family: $font-subheading;
        margin-bottom: 0;
      }
      .boat-name {
        font-family: $font-heading;
        font-size: 35px;
        text-transform: uppercase;
        margin:0 0 25px 0;
        letter-spacing: 1px;
      }
      .boat-btn, .compare-btn {
        @include ctaButton(19px);
      }
      .boat-btn {
        margin-right:10px;
      }
      @media screen and (max-width: $mobile-small) {
        .boat-btn, .compare-btn {
          display: inline-block;
          width: calc(50% - 5px);
          margin-left: -4px; // to remove the intrinsic space between inline-block elements
          text-align: center;
        }
      }
    }
    .swiper-pagination {
      @media screen and (min-width:$tablet-small){
        display: none;
      }
    }
  }

}
