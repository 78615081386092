.dealer-list-wrapper {
    padding: 40px 0;
    border-bottom: $section-break;
    .dealer-list-navigation {
        padding-bottom: 60px;
        @media screen and (max-width: $mobile) {
            padding-bottom: 20px;
        }
        .sub-heading {
            font-size: 15px;
            margin-bottom: 26px;
            text-transform: none;
            font-family: $font-subheading;

            @media screen and (max-width: $mobile) {
                margin-bottom: 15px;
            }
        }
        .btn-submit {
            width: 48%;
            margin: 0;
            padding: 10px;
            font-size: 12px;
            max-width: 320px;
            &.northamerica-list {
              @include media-breakpoint-up(sm) {
                margin-right: 15px;
              }
              @include media-breakpoint-up(md) {
                margin-right: 40px
              }
            }
            @include media-breakpoint-up(sm) {
              padding: 10px 25px;
              font-size: 16px;
            }
            @include media-breakpoint-up(md) {
              padding: 10px 25px;
              font-size: 16px;
            }
        }
    }

    .dealer-lists {
        margin: 0 auto;
        @include transition(1000);
        transition-delay: 750ms;
        opacity: 0;

        .country {
            margin-bottom: 20px;
            font-family: $font-heading;
            font-size: 30px;
            text-transform: uppercase;

            &.canada, &.usa {
                margin-bottom: 0;
            }

            @media screen and (max-width: $small) {
                margin-bottom: 0px;
            }
        }

        .dealer-list {

            .dealer-result-item {
                @media screen and (max-width: $mobile) {
                    padding-left: 0;
                    padding-right: 0;
                }
                .dealer-state {
                    margin-bottom: 15px;
                    font-size: 20px;
                    font-family: $font-heading;
                }

                .dealer-name {
                    display: block;
                    font-size: 18px;
                    text-transform: uppercase;
                    font-weight: bold;
                    margin: 0.05em 0 0.25em;
                    font-family: $font-heading;
                    @media screen and (max-width: $mobile) {
                      font-size: 16px;
                    }
                }
                p {
                    font-size: 18px;
                    @media screen and (max-width: $small) {
                        font-size: 4.5vw;
                    }
                }
            }
            &.international {
                @media screen and (min-width: $mobile) {
                    flex-direction: column;
                    max-height: 14000px;
                    gap: 1.25rem;

                    .dealer-result-item {
                        flex: 0 0 auto;
                        padding: 0 !important;
                    }
                }
            }
        }

        &.loaded {
            opacity: 1;
        }
    }

}
