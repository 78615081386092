.exploreGear {
  padding-top: 40px;
  padding-bottom: 60px;

  @media screen and (min-width:$mobile) {
    padding-top: 30px;
    padding-bottom: 30px;
  }


  &:nth-child(odd) {
    .media-holder {
      @media screen and (min-width:$mobile) {
        order: 2;
      }

    }
  }
  

  border-bottom: 10px solid $color-primary;
  .container {
    max-width: 130rem;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    .row {
      .exploreGear-image {
        a {
          img {
            transition: $trans;
            &:hover {
              opacity: 0.8;
            }
          }
        }
      }
      .media-holder {
        background-size: contain;
        background-repeat: no-repeat;

        @media screen and (max-width: $tablet-small) {
          margin-bottom: 28px;
          order: 1;
        }

        figure {
          margin: 0;
          background-size: cover;
          background-position: center;

          img {
            object-fit: cover;
            width: 100%;
            height: 100%;
          }

          &::before {
            content: '';
            display: block;
            padding-top: 66.6666666667%;

            @media screen and (min-width: $mobile-small) {
              padding-top: 400px;
            }
          }
        }
        
      }
      .content_50-50 {
        @media screen and (max-width: $tablet-small) {
          order: 2;
        }
        .exploreGear-content {
          .section-title {
            font-size: 25px;

            @media screen and (max-width: $mobile) {
              font-size: 17px;
            }
          }
          .subheading {
            color: $color-primary;
            @media screen and (max-width: $mobile) {
              font-size: 17px;
            }
          }
          
        }
      }
    }
  }

}
