.page-404 {
  padding: 200px 20px 100px 20px;
  min-height: 400px;
  background-color: $color-black;
  .row {
    .col-12 {
      color: $color-white;
      h3 {
        font-family: $font-heading;
        font-size: 5rem;
        line-height: 1;
        text-transform: uppercase;
      }
      p {
        font-family: $font-body;
        font-size: 1.8rem;
        line-height: 1.6667;
      }
      a {
        font-size: 25px;
        font-family: $font-heading;
        color: $color-primary;
        margin-top: 20px;
      }
    }
  }
}
