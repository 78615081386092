// Single boat view

.review-intro {
  padding: 0 50px;

  @media(min-width: $mobile) {
    padding: 0 135px;
  }
  &::after {
    content: '';
    display: block;
    border-bottom: $section-break;
    margin-top: 30px;
    @media(min-width: $mobile) {
      margin-top: 10px;
    }
  }
}
.review-avg {
  display: block;
}
.stars {
  margin: 0 0 10px 0;
  display: block;
}
#reviews, #reviews-manual {


  .swiper-button-next,.swiper-button-prev {
    margin: 0;
  }

  .swiper-button-prev {
    left: 40px;
    @media screen and (max-width: $large-screens) {
      left: 20px;
    }
    @media screen and (max-width: $mobile-small) {
      left: -10px;
    }
  }
  .swiper-button-next {
    right: 40px;
    @media screen and (max-width: $large-screens) {
      right: 20px;
    }
    @media screen and (max-width: $mobile-small) {
      right: 0;
    }
  }

  .review-slider{
    width: 100%;
  }

  border-bottom: $section-break;
  padding: 40px 0;
  .container {
    @media(max-width:$mobile) {
      max-width: 100%;
      padding: 0;
    }
    .row {
      margin: 0 auto;

      @media screen and (max-width: $mobile) {
        margin: 0 auto;
      }
      .section-title {
        margin-bottom: 10px;
      }
      .swiper-container {
        margin-top: 10px;
        .swiper-wrapper {
          .review {
            padding: 0 35px;
            @media(min-width: $mobile) {
              padding: 0 120px;
            }
            .review-meta {
              color: $color-gray;
              .stars {
                margin-right: 10px;
              }
              p {
                //color: $color-gray;
              }
            }
            .review-header {
              text-transform: uppercase;
              font-weight: bold;
              //font-family: $font-heading;
              font-size: 25px;
              margin-top: 20px;

              @media screen and (max-width: $mobile) {
                font-size: 17px;
              }
            }
            .review-text.read-more-button {
              &:after {
                content: "Read More";
                cursor: pointer;
                text-transform: uppercase;
                color: $color-primary;
                margin: 0 5px;
                border-bottom: $section-break;
                border-color: $color-primary;
              }
            }
            .review-text {
              .readmore {
                .dots {
                }
                color: $color-primary;
                margin: 0 5px;
                text-transform: uppercase;
              }
              .overflow {
                display: none;
              }
            }
          }
        }
      }

      .review-copy {

        h4.review-header {
          text-transform: uppercase;
          font-family: $font-heading;
          font-size: 25px;
          font-weight: 500;
          text-align: left;
          @include media-breakpoint-down(sm) {
            margin-bottom: 0;
            &:after {
              display: none;
            }
          }
          @include media-breakpoint-up(md) {
            text-align: center;
          }

        }
        p.review-text {
          font-family: $font-body;
          font-size: 25px;
          display: inline;
          @include media-breakpoint-up(md) {
              display: block;
          }
        }
        .readmore {
          display: inline;
          @include media-breakpoint-up(md) {
              display: block;
          }
        }
        .readless {
          display: none;
        }
        .readmore, .readless {
          color: $color-primary;
          font-family: $font-body;
          text-decoration: none;
          text-transform: uppercase;
        }
      }
    }
    .disclaimer {
      color: $color-gray;
      font-size: 15px;
      margin-top: 71px;
      text-align: center;

      @media screen and (max-width: $mobile) {
        font-size: 12px;
        margin-top: 30px;
      }
    }
  }

  .stars, .rating {
    width: 122px;
    height: 20px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left center;
  }
  .stars {
    background-image: url(/assets/theme-axis/assets/img/stars-white@2x.png);
    position: relative;

    @media screen and (max-width: $mobile) {
      background-image: url(/assets/theme-axis/assets/img/stars-white.png);
    }

    .rating {
      background-image: url(/assets/theme-axis/assets/img/stars-gold@2x.png);
      position: absolute;
      left: 0;
      top: 0;

      @media screen and (max-width: $mobile) {
        background-image: url(/assets/theme-axis/assets/img/stars-gold.png);
      }
    }
  }
}

#warranty {
  background-color: $color-black;
  padding: 50px 0;
  border-bottom: $section-break;
  .container {
  }
  .row {
    margin: 0 auto;
    align-items: center;
    .warranty-logo {
      text-align: center;
      img {
        max-width: 350px;
        margin: 0 auto 20px auto;
      }
    }
    h3.section-title {
      font-size: 40px;
    }
    h4.section-title {
      text-transform: uppercase;
      font-size: 25px;
      font-weight: 400;
      margin-bottom: 30px;
    }
    .warranty-copy {
      text-align: left;
      @include media-breakpoint-up(md) {
          text-align: center;
      }

      p.paragraph {
        font-size: 25px;
      }
      .btn.btn-submit {
        font-size: 25px;
        height: 45px;
        margin-top: 40px;
        max-width: 320px;
        width: 100%;

        @include media-breakpoint-up(md) {

        }
      }
    }
  }
}
