.warrantyRepeatable {
  padding: 50px 0px;
  text-align: left;
  border-bottom: $section-break;
  .intro {
    text-align: center;
  }
  .intro-description {
    font-family: $font-heading;
    font-size: 40px;
  }
  img {
    width: 300px;
    margin: 0 auto;
  }
  .warranty-list {
    margin: 40px auto 40px auto;
    padding: 0;
    li {
      list-style: none;
      padding: 10px 5px;
      &:nth-child(odd) { 
        background-color: #E0E0E0; 
      }
    }
    .label {
      font-family: $font-subheading;
      display: block;
      text-transform: uppercase;
    }
  }
  
  .warranty-text {
    .descrpitionHeading {
      font-family: $font-heading;
      font-size: 40px;
    }
    .descriptionParagraph {
      margin-top: 30px;
    }
  }
}

//warranty table styles
.warranty-wrapper {
  overflow: scroll; 
}
.warranty-result-table {
  border-spacing: 0px;
  margin: 0 0 40px;
  width: 100%;
  .warranty-result-head {
    th {
      border-bottom: 1px solid $color-lightgray;
      border-top: 1px solid $color-lightgray;
      font-size: 12px;
      color: $color-gray;
      &.column-empty{
        border-top: none;
        border-bottom: 1px solid $color-lightgray;
      }
      &.column-1 {
        border-bottom: none;
      }
    }
    tr:hover {
      th {
        background-color: $color-white;
        &.column-empty{
          background-color: transparent;
        }
      }
    }
  }
  th, td {
    background-color: $color-white;
    border-right: 1px solid $color-lightgray;
    padding:10px  1.3rem;
    &.column-empty {
      border: none;
      background-color: transparent;
    }
  }
  td {
    &.column {
      color: $color-lightgray;
      &.column-1 {
        color: $color-black;
        border-right:1px solid $color-primary;
      }
    }
  }
  th.column-empty{
    border-right: 1px solid $color-primary;
  }
  th.column-1 {
    box-sizing: border-box;
    border-right: 1px solid $color-primary;
    border-top: 3px solid $color-primary;
  }
  tr {
    &:hover {
      th, td { 
        background-color:#f8f8f8 ;
      }
    }
  }
  .warranty-result-body {
    th {
      border-left: 1px solid $color-lightgray;
      border-right: 1px solid $color-primary;
      border-bottom: none;
    }
    tr:last-child {
      td, th {
        border-bottom: 1px solid $color-lightgray;
        &.column-1 {
          border-bottom: 1px solid $color-primary;
        }
      }
    }
  }

}