.profile-list {
  // section
  padding-top: $site-container-padding;
  padding-bottom: $site-container-padding;

  .profile-card-wrapper {
    width: 100%;
  }

  .profile-card {
    // a.col-[x]
    &__image-wrapper {
      position: relative;
      width: 100%;
      height: 0;
      padding-bottom: 185%;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    &__label {
      @include transition(150);
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      padding: 0 15px;
      text-align: center;

      &__title {
        //h4
        font-family: $font-heading;
        font-size: 35px;
        @media screen and (min-width: $tablet-small) {
          font-size: 55px;
        }
        text-transform: uppercase;
      }

      &__subtitle {
        font-family: $font-subheading;
        font-size: 22px;
        text-transform: uppercase;
      }
    }

    &:hover,
    &:active {
      .profile-card__label {
        color: adjust-color($color-primary, $lightness: 15%);
      }
    }
  }

  &.theme-dark {
    .profile-card {
      &__label {
        color: $color-white;
      }

      &:hover,
      &:active {
        .profile-card__label {
          color: adjust-color($color-white, $lightness: -15%);
        }
      }
    }
  }
}