.malibuSlider {
  overflow: hidden;
  border-bottom: $section-break;
  @media screen and (max-width: $mobile) {
    padding-bottom: 15px;
  }

  .swiper-container {
    margin: 0 -6%;
    height: 550px;
    @include media-breakpoint-up(md) {
      margin: 0 -#{$site_container_padding};
    }

    .swiper-wrapper {
      .malibuSlide, .event-slide {
        font-family: $font-body;
        overflow: hidden;
        display: flex;
        @media screen and (max-width: $tablet-small) {
          display: block;
          margin-bottom: 0;
        }
        .slide-image {
          width: 70%;
          position: relative;
          left:0;
          box-shadow: 20px 0 40px 0 rgba(0, 0, 0, .4);
          @media screen and (max-width: $tablet-small) {
            width: 100%;
            height: 300px;
            margin: 0 auto;
          }
        }
        img {
          display: block;
          width: 100%;
          object-fit: cover;
          object-position: center;
          height: 100%;
        }  
        .caption {
          color: #0c1215;
          display: flex;
          flex-flow: column;
          justify-content: center;
          margin: 20px 30px;
          font-family: $font-heading;
          font-weight: bold;
          width: 50%;
          max-width: 500px;
          .caption-kicker {
            @include h6;
            font-family: $font-subheading;
          }
          .caption-title {
            font-weight: bold;
            font-family: $font-heading;
            text-transform: uppercase;
            font-size: 24px;
            width: 100%;
            @include media-breakpoint-up(md) {
              font-size: 36px;
            }
          }
          p {
            font-family: $font-body;
            font-weight: lighter;
            margin-left: 0;
          }
          a {
            text-decoration: none;
          }
          a > span{
            color: #0c1215;
            font-family: "AtlasTypewriter-Bold",Arial,sans-serif;
            line-height: 1.3;
            border-bottom: 2px solid #0c1215;
          }
          @media screen and (max-width: $tablet-small) {
            width: 100%;
            margin: 0;
            padding: 30px;
          }   
        }
      }

      .event-slide {
        .caption {
          @include media-breakpoint-up(md) {
            margin-top: 36px;
          }
        }
      }
    }
    .swiper-pagination {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%,0%);
      line-height: 0;

      @media screen and (max-width: $mobile) {
        padding: 0 0 70px;
        padding: 10px 0;
      }

      @media screen and (min-width: $tablet-small) {
        position: absolute;
        bottom: 0;
      }

      .swiper-pagination-bullet {
        margin: 0 4px;
        @include media-breakpoint-up(md) {
          margin: 0 8px;
        }
        &:only-child {
          display: none;
        }
      }
    }
    .swiper-button-next, .swiper-button-prev {
      top: 50%;
      transform: translateY(-50%);
      &.hide, &.swiper-button-disabled {
        display: none;
      }
      @media screen and (max-width: $mobile) {
        top: 200px;
      }
    }  
  }
}
