.photoGrid {
  padding: 60px 0;
  border-bottom: $section-break;
  text-align: center;

  @media screen and (max-width:$mobile){
    padding: 30px 0;
  }

  .pagination-wrapper {
    width: 100%;
    position:absolute;
    bottom: 0;
    text-align: center;
  }
  .pagination-width {
    margin: 0 auto;
    width: 100%;
    max-width: 1570px;
    text-align: left;
    padding: 0 0 0 20px;
    @media screen and (min-width: 1556px) {
      padding-left:0px;
    }
    @media screen and (max-width: $tablet-small) {
      padding: 0;
      text-align: center;
    }
  }

  .container {
    padding: 0;
  }
  .tab-nav-m {
    // max-height:38px;
    // overflow: hidden;
    // margin: 0 auto 10px;
    // display: inline-block;
    // border:2px solid $color-black;
    .nav-tabs {
      .nav-tab-item  {
        cursor: pointer;
        text-transform: uppercase;
        a.nav-tab-link {

          &.active {
            color:$color-primary;
            display:block;
            span.underline {
              border-color: $color-primary !important;
            }
          }
        }
      }
    }
  }
  .photo-tabs {
    padding: 20px 0;
    justify-content: space-around;
    margin: 0 auto;

    @media screen and (max-width:$mobile){
      padding: 0 0 20px;
    }
  }
  .tab-content {
    .imageCarouselWrapperSlider {
      display: block;
      @include media-breakpoint-up(md) {
          display: none;
      }
      .swiper-slide {
        margin: 0 auto;
        cursor: pointer;
        img {
          width: 100%;
          margin: 0 auto;
          @include media-breakpoint-up(md) {
            width: 82%;
          }
        }
        .caption {
          width: 75%;
          margin: 0 auto;
          font-size: 15px;
          font-family: $font-body;
          padding-top: 10px;
          @include media-breakpoint-up(md) {
            width: 82%;
          }
        }
      }
    }
    .photo-grid {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      padding: 0 15px;
      justify-content: center;
      .photo-grid-photo {
        overflow: hidden;
        width: 50%;
        height: 0;
        opacity: 0;
        transition: 0.2s opacity ease-in-out;
        margin-bottom: 0px;
        div {
          width: 100%;
          height: 100%;
        }
        &:nth-child(-n+4) {
          height: auto;
          opacity: 1;
          margin-bottom: 30px;
        }
        @media screen and (min-width: $tablet-small) {
          height: auto;
          opacity: 1;
          margin-bottom: 30px;
        }
        cursor: pointer;
        img {
          transition: $trans;
          object-fit: cover;
          width: 100%;
          height: 100%;
          object-position: center;
          aspect-ratio: 1 / 1;
          &:hover {
            opacity: 0.9;
          }
        }
      }
      &.loaded {
          .photo-grid-photo {
            height: auto;
            opacity: 1;
            margin-bottom: 30px;
          }
        }

      div[id^="photoGridModal"] {
        .modal-dialog {
          width: 85%;
          max-width: 1150px;
          overflow: hidden;
          .modal-content {
            .modal-body {
              text-align: center;
              padding: 0;
              .close {
                position: absolute;
                top: 20px;
                right: 20px;
                color: $color-white;
                font-size: 2em;
              }
              .swiper-wrapper {
                overflow: visible;
                .swiper-slide {
                  
                  .carousel-image {
                    img {
                      display: block;
                      width: 100%;
                      height: 100%;
                      object-fit: cover;
                    }
                  }

                  .course-caption {
                    position: relative;
                    padding: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    text-align: left;

                    @media screen and (max-width: $mobile-small) {
                      padding: 10px;
                    }

                    .caption {
                      font-size: 17px;
                      padding-right: 20px;
                      color: $color-black;

                      @media screen and (max-width: $mobile) {
                        font-size: 12px;
                      }
                    }
                  }
                }
                
              }
              
            }
          }
        }

      }
    }
  }
  .more-photos {
    width: 100%;
    max-width: $site_container;
    margin: 16px auto 0;
    text-align: left;
    padding:0 35px;
    font-family: $font-subheading;
    cursor: pointer;
    span {
      border-bottom: $section-break;
    }
    &.loaded {
      display: none;
    }
    @media screen and (min-width: $tablet-small) {
      display: none;
    }
  }
  .carousel-control-prev, .carousel-control-next {
    top: 10%;
    bottom: 10%;

    @media screen and (max-width: $mobile) {
      top: 10%;
      bottom: 30%;
    }
  }
}