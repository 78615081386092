/********************************
	BREAKPOINTS
********************************/
$small: 350px;
$mobile-small: 500px;
$mobile: 768px;
$tablet-small: 990px;
$tablet: 1024px;
$medium-screens: 1200px;
$large-screens: 1400px;


/********************************
	COLORS
********************************/
$color-primary: #F90000;
$color-secondary: #3e85b5;
$color-white: #ffffff;
$color-black: #0c1215;
$color-gray: #727272;
$color-lightgray: #E0E0E0;
$color-dark-gray: #1e272d;

/****** MISC *******/

$section-break: 2px solid $color-black;
$trans: all 0.3s ease-in-out;

/********************************
	FONTS
********************************/

/*@import url('https://fonts.googleapis.com/css?family=Arimo&display=swap');
$arimo: 'Arimo', sans-serif;*/
$font-heading: 'Druk-Super', Arial, sans-serif;
$font-subheading: 'AtlasTypewriter-Bold', Arial, sans-serif;
$font-body: 'AtlasGrotesk-Regular', Helvetica, Arial, sans-serif;
$fontawesome: 'Font Awesome 5 Pro', sans-serif;

$em-base: 16; /* Henseforth known as 1em  |  used for em() mixin */
$base-font-size: #{$em-base}px;

$em-base-mobile: 14; /* Henseforth known as 1em  |  used for em() mixin */
$base-font-size-mobile: #{$em-base-mobile}px;

@font-face {
  font-family: 'Druk-Super';
  src: url(/assets/theme-axis/assets/fonts/Druk-Super.woff2) format('woff2');
  font-display: block;
}

@font-face {
  font-family: 'AtlasTypewriter-Bold';
  src:
    url(/assets/theme-axis/assets/fonts/AtlasTypewriter-Bold.woff2) format('woff2');
  font-display: block;
}

@font-face {
  font-family: 'AtlasGrotesk-Regular';
  src: url(/assets/theme-axis/assets/fonts/AtlasGrotesk-Regular.woff2) format('woff2');
  font-display: block;
}

@font-face {
  font-family: 'Font Awesome 5 Pro';
  src: url(/assets/theme-axis/assets/fonts/fontawesome-pro-5.9.0-web/webfonts/fa-regular-400.woff) format('woff');
  font-display: block;
}

// Font Awesome
// set in ./src/sass/library/__library-overwrites.scss

/********************************
	WEIGHTS
********************************/
$light: 300;
$normal: 400;
$semibold: 500;
$bold: 700;


/********************************
	Z-INDEX
********************************/
$z_below: -1;
$z_default: 1;

$z_low: 10;
$z_med: 20;
$z_high: 30;
$z_overlay: 100;
$z_max: 1000;


/********************************
    WIDTHS, HEIGHTS, SPACING
********************************/

/*** CONTAINER VARS ***/
// Creates a content container with built-in padding so your content is always off the edge of the window in a centrally controlled location
$site_container: 1569px; // the max width of the content, not including padding
$container_sm: 1024px;
// Global content padding from container
$site_container_padding: 30px; // Desktop
$site_container_padding_mobile: 5%; // Mobile (rec: percentage)
// Define container using calc - allows for easy changes to individual container elements
$container: calc(#{$site_container} + (#{$site_container_padding} * 2)); // Desktop
$container_mobile: calc(#{$site_container} + (#{$site_container_padding_mobile} * 2)); // Mobile


/*** HEADER ***/
$header_height: 75px;
$header_height_mobile: 100px;
