.rotatable-highlight {
  border-bottom: $section-break;
  padding-bottom: 60px;

  @media screen and (max-width: $mobile) {
    padding-bottom: 30px;
  }
  &.theme-light {
    background: $color-white;
    .tab-content
      .object-item
      .object-image
      .hotspots-image
      .hotspots-container
      .object-hotspot {
      &::before,
      &::after {
        background-color: $color-primary;
      }
      .inner-circle {
        background-color: $color-white;
        border-color: $color-primary;
      }
    }
  }
  &.theme-dark {
    background: $color-primary;
    .tab-content
      .object-item
      .object-image
      .hotspots-image
      .hotspots-container
      .object-hotspot {
      &::before,
      &::after {
        background-color: $color-white;
      }
      .inner-circle {
        background-color: $color-primary;
      }
    }
  }
  .container {
    overflow: hidden;
  }
  .block-heading {
    .section-title {
      padding-top: 40px;
    }
    .sub-heading {
      font-size: 25px;
      margin-bottom: 20px;

      @media screen and (max-width: $mobile) {
        font-size: 17px;
        margin-bottom: 5px;
      }
    }
  }

  .tab-menu-category {
    display: flex;
    justify-content: space-around;
    margin: 30px auto;

    @media (max-width: $mobile) {
      margin: 0 0 20px;
      flex-wrap: wrap;
    }

    &.font-size-vw {
      .tab-category {
        font-size: 25px;
        padding: 0 10px 3px 10px;
        text-align: center;

        @media (max-width: $mobile) {
          font-size: 3vw;
          padding: 0 1vw;
          margin: 0;
        }
      }
    }

    .tab-category {
      font-family: $font-heading;
      font-size: 25px;
      text-transform: uppercase;
      display: inline-block;
      text-align: center;
      margin: 0 5px;
      position: relative;
      left: 100%;
      opacity: 0;

      @media (max-width: $tablet-small) {
        flex: 1;
        font-size: 17px;
        margin-bottom: 30px;
      }

      &.active {
        span.underline {
          border-bottom: 5px solid $color-primary !important;
          padding-bottom: 15px;
          display: inline-block;
        }
      }
      &:hover {
        cursor: pointer;
      }
    }
  }

  .tab-content {
    max-width: 90%;
    margin: 0 auto;
    position: relative;

    @media (max-width: $tablet) {
      max-width: 100%;
    }

    .object-item {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      visibility: hidden;
      @include transition(250);

      &.active {
        position: relative;
        opacity: 1;
        visibility: visible;
      }

      .info-block {
        padding: 0;
        overflow: hidden;

        @media (max-width: $mobile) {
          order: 2;
        }

        .info-item {
          display: flex;
          flex-wrap: wrap;
          align-content: center;
          justify-content: center;
          top: 0;
          height: 100%;
          @include transition(250);

          .object-heading,
          .hotspot-heading {
            font-size: 25px;
            opacity: 1;

            &::after {
              width: 75px;
            }
          }

          .copy {
            font-size: 25px;

            @media (max-width: $tablet) {
              font-size: 15px;
            }
          }
          &.hotspot-info {
            top: -100%;
            position: relative;

            &.show {
              top: 0%;
            }
            &.remove {
              top: 100%;
              position: absolute;
            }
          }
          &.object-info.hide {
            top: 100%;
            display: none;
            visibility: hidden;
          }
        }

        // &::after {
        //     content: " ";
        //     padding: 100%;
        // }
      }

      .object-image {
        @media screen and (max-width: $mobile) {
          margin-bottom: 15px;
        }
        .hotspots-image {
          position: relative;
          width: 100%;
          max-width: 1010px;
          margin: auto;
          aspect-ratio: 676 / 411;
          img{
            position: absolute;
            display:block;
            -webkit-user-drag: none !important;
            -webkit-user-select: none !important;
            -webkit-touch-callout: none !important;
            width: 100%;
            max-width: 1010px;
          }
          .hotspots-container {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;

            .object-hotspot {
              position: absolute;
              display: flex;
              justify-content: center;
              align-items: center;
              z-index: 5;
              width: 35px;
              height: 35px;
              line-height: 0;
              @include transition(150);

              @media screen and (max-width: $tablet) {
                // width: 20px;
                // height: 20px;
              }

              &::before {
                content: "";
                position: absolute;
                width: 3px;
                height: 80%;
                @media screen and (min-width: $tablet) {
                  height: 100%;
                }
                margin: 0 auto;
              }

              &::after {
                content: "";
                position: absolute;
                width: 80%;
                @media screen and (min-width: $tablet) {
                  width: 100%;
                }
                height: 3px;
                top: 50%;
                transform: translateY(-50%);
              }

              .inner-circle {
                z-index: 1;
                @include transition(150);
                display: block;
                border: solid;
                border-width: 3px;
                border-color: $color-white;
                width: 15px;
                height: 15px;
                @media screen and (min-width: $tablet) {
                  width: 25px;
                  height: 25px;
                }
              }

              &:hover {
                text-decoration: none;
                width: 45px;
                height: 45px;
                margin: -5px;

                .inner-circle {
                  width: 30px;
                  height: 30px;
                }

                @media screen and (max-width: $tablet) {
                  // width: 40px;
                  // height: 40px;
                  // padding: 6px;
                  // margin: -9px;
                  // border-width: 2px;
                }
              }
              &.active {
                .inner-circle {
                  background-color: transparent;
                }
              }
            }

            .hotspot-copy {
              position: absolute;
              top: 0;
              left: 0;
              display: none;
            }
          }
        }
      }
    }
  }
}

.model-rotator .swiper-pagination-bullets {
  bottom: -15px;
}
.model-rotator .swiper-button-prev{

  @media screen and (max-width:$mobile){
    width: unset;
    margin-left: -20px;
    padding-left: 9px;
    padding-right: 28px;
  }
}

.model-rotator .swiper-button-next{
  @media screen and (max-width:$mobile){
    width: unset;
    margin-right: -20px;
    padding-right: 9px;
    padding-left: 28px;
  }
}