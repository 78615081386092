.boat-specs {
  background-color: $color-primary;
  color: white;
  padding: 20px 0 0 0;
  h2{
    font-family: "Druk-Super",Arial,sans-serif;
  }
  .container {
    max-width: $container_sm;
    padding: 0 25px;
  }
  .specs-flex {
    padding-bottom: 20px;
    @media screen and (min-width: $tablet-small) {
      column-count: 2;
      padding-bottom: 50px;
    }
    .spec {
      display: none;
      margin-bottom: 10px;
      &:nth-child(-n + 2) {
        display: block;
      }
      @media screen and (min-width: $tablet-small) {
        display: block;
      }
    }
    &.load {
      .spec {
        display: block;
      }
    }
  }

  .td-label {
    font-family: $font-subheading;
    font-weight: bold;
  }
  .load-more {
    display: inline-block;
    border-bottom: 1px solid white;
    margin-bottom: 20px;
    font-family: $font-subheading;
    cursor: pointer;
    @media screen and (min-width: $tablet-small) {
      display: none;
    }
    &.load {
      display: none;
    }
  }
  .spec-btns {
    border-bottom: $section-break;
    width: 100%;
    background-color: white;
    text-align: center;
    padding: 20px 0;
    a {
      @media screen and (min-width: $tablet-small) {
        margin-right: 5px;
      }
    }
  }
}