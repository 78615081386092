.quote-block {
  padding: 20px 5rem;
  max-width: 72rem;
  margin: 0 auto;
  @media screen and (max-width: $tablet) {
    padding: 20px;
  }
  .quote {
    font-family: $font-heading;
    font-size: 40px;
    line-height: 1.2;

    @media screen and (max-width: $tablet) {
      font-size: 25px;
    }

    @media screen and (max-width: $mobile) {
      font-size: 17px;
    }
  }
  .author {
    margin-top: 30px;
    text-transform: uppercase;
    font-family: $font-heading;
    color: $color-primary;
    @media screen and (max-width: $mobile) {
      margin-top: 10px;
    }
    span.seperator {
      color: $color-black;
      margin: 0 10px;
      display: inline-block;
      vertical-align: middle;
    }
  }
}
