#heroBoatDetail {
  position: relative;
  border-bottom: $section-break;
  height: 130vw;
  max-height: 827px;

  @media screen and (max-width: $mobile) {
    max-height: 400px;
  }

  .boat-image-wrapper {
    position: absolute;

    figure.boat-image {
      width: 100%;
      max-width: 1200px;
      margin: 0 auto;
      padding: 0 40px;
      bottom: 0;
      left: 0;
      right: 0;
      position: relative;
      z-index: 1000;
      img {
        margin-bottom: -21%;
        &.small {
          display: block;
          margin-bottom: -22%;
          @media screen and (min-width: $mobile) {
            display: none;
          }
        }
        &.normal {
          display: none;
          @media screen and (min-width: $mobile) {
            display: block;
          }
        }
      }
    }
  }

  video {
    object-fit: cover;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    position: absolute;
  }

  .hero-text-wrapper {
    position: relative;
    overflow: hidden;
    width: 100%;
    .hero-text {
      z-index: 2;
      padding: 15% 40px;

      @media screen and (max-width: $mobile-small) {
        padding: 30px;
      }

      .section-title {
        width: 100%;
        padding: 0 12vw;
        z-index: 999;
        line-height: 1;
        @media screen and (max-width: $mobile-small) {
          padding: 0;
        }
      }
    }
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: $color-black;
    opacity: .40;
    z-index: 1;
  }

    video {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 0;
      overflow: hidden;
    }
}
#video-header-bottom {
  border-bottom: $section-break;
  .video-header-bottom {
    padding-bottom: 30px;
    position: relative;
    .boat-text {
      padding-top: calc((65% * (.41/2) + 5rem));
      padding-bottom: 2rem;
      overflow: hidden;
      max-width: 1200px;
      margin: 0 auto;

      @media screen and (max-width: $tablet) {
        padding-top: calc((65% * (.41/2) + 2rem));
        padding-bottom: 0;
      }
      h3 {
        font-family: $font-heading;
        margin-bottom: 20px;
        font-weight: 400;
        font-size: 40px;
        margin: 0 auto;
        max-width: 800px;

        @media screen and (max-width: $mobile) {
          font-size: 20px;
          line-height: 20px;
        }
      }
      p {
        font-family: $font-body;
        font-size: 25px;
        line-height: 30px;
        max-width: 1100px;

        @media screen and (max-width: $mobile) {
          font-size: 15px;
          line-height: 20px;
        }
      }
    }
  }
}
