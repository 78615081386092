// Block Cards
.component-cards {
	padding: 12rem 0;
	.description {
		.copy {
			padding-top: 4rem;
		}
	}
	.image-info-card-overview {
		margin-top: 8rem;
		.component-image-info-card {
			@include background-size(cover);
			padding: 4rem;
			position: relative;
			.inner-content {
				position: relative;
				.heading { 
					svg {
						width: 1.5rem;
						height: 2.3rem;
						margin-left: 1.5rem;
						fill: #209ac7;
					}
				}
				.copy { 
					margin-top: 3rem;
				}
			}
			&:before{
				background: linear-gradient(to bottom,transparent 0,rgba(0,0,0,.9) 100%);
				position: absolute;
				left: 0;
				bottom: 0;
				width: 100%;
				height: 80%;
				content: "";
			}
		}
	}
}