.press-release-feed {
  border-bottom: $section-break;
  padding-bottom: 20px;
  .section-title {
    text-align: center;
  }
  .intro {
    .row {
      padding: 40px 0 20px;
    }
  }
  .press-releases {
    display: flex;
    flex-wrap: wrap;
    gap:2%;
    align-items: stretch;
    justify-content: center;
    .press-release {
      overflow: hidden;
      height: 0;
      transition: 0.2s opacity ease-in-out;
      display: flex;
      flex-direction: column;
      justify-content: stretch;
      &:nth-child(-n+2 ), &.load-more {
        height: auto;
        opacity: 1;
      }
      @media screen and (min-width:$tablet-small){
      &:nth-child(-n+4 ), &.load-more {
        height: auto;
        opacity: 1;
      }
      }
      @media screen and (min-width:$tablet-small){
      max-width: 23%;
      flex: 0 0 23%;
      }
      flex: 0 0 48%;
      .feature-image {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 100%;
        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .content {
        padding: 20px 0;
        flex-grow: 1;
        display: flex;
        flex-wrap: wrap;
        @media screen and (min-width:$tablet-small){
          padding: 30px 0;
        }
        .pagetitle-link {
          width: 100%;
          color: $color-black;
          font-family: $font-subheading;
          font-size: 18px;
          display: block;
          padding-bottom: 20px;
          text-decoration: none;
          @media screen and (max-width: $tablet-small - 1px) {
            padding-bottom: 10px;
          }
        }
        .link {
          @include ctaButton(19px);
          margin-top: auto;
          @media screen and (max-width: $tablet-small - 1px) {
            display: block;
            width: 100%;
            text-align: center;
          }
        }
      }
    }
    &.active {
    .press-release { 
      height: auto;
      opacity: 1;
    }
    }
    &.one {
      .press-release {
        &:nth-child(-n+4 ) {
          height: auto;
          opacity: 1;
        }
        @media screen and (min-width:$tablet-small){
          &:nth-child(-n+8 ) {
            height: auto;
            opacity: 1;
          }
        }
      }
      @media screen and (min-width:$tablet-small){
        .press-load-more {
          display: none;
        }
      }
    }
    &.two {
      .press-release:nth-child(-n+8 ) {
          height: auto;
          opacity: 1;
        }
        @media screen and (min-width:$tablet-small){
          .press-release:nth-child(-n+12 ) {
            height: auto;
            opacity: 1;
          }
        }
    }
    &.three {
      .press-release:nth-child(-n+12 ) {
          height: auto;
          opacity: 1;
        }
    }
    &.four {
      .press-release:nth-child(-n+10 ) {
          height: auto;
          opacity: 1;
        }
    }
    &.four {
      .press-release:nth-child(-n+12 ) {
          height: auto;
          opacity: 1;
        }
    }
  }
  .press-load-more {
    display: block;
    width: max-content;
    margin:0 auto;
    cursor: pointer;
    font-family: $font-subheading;
    &::after {
    content: "";
    border-bottom: 1px solid $color-black;
    display: block;
    }
    &.two {
      @media screen and (min-width:$tablet-small){
        display: none;
      }
    }
    &.three, &.hide {
      display: none;
    }
  }
}
