.text-block {
  box-sizing: border-box;
  padding-top: 20px;
  padding-bottom: 10px;
  border-bottom: $section-break;
  .container {
    
    @media screen and (min-width:$tablet-small){
      display: flex;
      .col {
        min-width: 50%;
        flex-basis: auto;
        display: block;
        padding: 0;
        h2 {
          min-height: 28px;
        }
      }
    }
  }
  a {
    @include body-link();
    &.btn {
      color: white;
      border-color: $color-primary;
      margin-right:5px;
      &:hover {
        color: $color-primary;
      }
    }
  }
  p {
    padding-bottom: 20px;
  }
  &.red {
    background-color: $color-primary;
    color:white;
    border-bottom: none;
    a {
      color: white;
      border-color: white;
      &:hover {
        border-color: $color-dark-gray;
      }
      &.btn {
        color:$color-primary;
        background-color: white;
        &:hover {
          border-color: white;
          background-color: $color-primary;
          color: white;
          }
      }
    }
  }
}