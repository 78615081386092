.dealer-page {
    .dealer-description {
        padding: 76px 0 0;
        .container {
            max-width: $container_sm;
            .text-center {
              text-align: left !important;  
            }
            
        }

        @media screen and (max-width: $mobile) {
            padding: 30px 0;
        }
        .paragraph {
            width: 100%;
            text-align: left !important; 
        }
    }
    .dealer-events {
        .col-12, .caption {
            max-width: $container_sm;
            margin:0 auto;
            opacity: 1 !important;
            @media screen and (min-width: $tablet-small) {
            padding-left:30px;
            }
        }
        overflow: hidden;
        padding-top: 36px;
        @media screen and (max-width: $mobile) {
            padding-top: 33px;
        }
        .sub-heading {
            margin-bottom: 40px;
            @media screen and (max-width: $mobile) {
                margin-bottom: 20px;
            }
        }
        .slide-image {
            position: relative;
            height: 680px;
            position: relative;
            background-size: cover;
            background-position: center;
            margin: 0 auto;
            @media screen and (min-width: $tablet-small) {
            border: solid $color-primary 30px;
            border-bottom: none;
            border-top: none;
            }
            @media screen and (max-width: $mobile) {
              height: 400px;
              margin: 0 auto 40px;
            }
          }
          .caption {
              padding-bottom: 35px;
              a.readmore {
                  color: white;
                  border-bottom: 1px solid white;
                  &:hover {
                      text-decoration: none;
                  }
              }
          }
          .caption-title {
              font-family: $font-subheading;
              font-size: 24px;
              margin-top:37px ;
          }
    }

    #dealer-locator {
        .site-frame {
            max-width: $container_sm;
            text-align: left;
            .find-dealer-result  {
                padding-top:55px ;
            }
        }
    }
}
.dealer-result-item {
    .dealer-name {
        width: unset;
        a {
            font-size: 18px;
            color: $color-dark-gray;
            font-family: $font-subheading;
        }
    }
    .dealer-website {
        padding-bottom: 20px;
    }
}