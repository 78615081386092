.component-block_block-load-more-content {
  padding: 0;

  .load-more-content__title {
    padding: 40px 0 20px;
  }

  .sub-heading {
    margin-bottom: 40px;

    @media screen and (max-width: $mobile) {
      margin-bottom: 20px;
    }
  }

  &.theme-light {

    .malibuSlider {
      .swiper-pagination-bullet-active {
        background-color: $color-primary;
      }
    }
    .section-title {
    }
  }
  .photoGrid {
    .section-title {
      display: none;
    }
  }
}
