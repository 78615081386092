.heroVideoSimple, .heroVideoStory {
  padding: 0;
  border-bottom: $section-break;
  position: relative;
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: black;
    opacity: 0.25;
    z-index: 1;
  }
  .video-cover {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    height: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    padding: 30px 17px;
    position: relative;

    .bg-image {
      position: absolute;
      object-fit: cover;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
    }

    &::after {
      opacity: 1;
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      margin-bottom: -1px;
      content: "";
      display: block;
      height: 65%;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 0;
      background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(black));
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, black 100%);
    }
  }
  .play-button {
    &:before {
      margin-left: 34px;
      @include media-breakpoint-up(md) {
        margin-left: 32px;
      }
    }
  }
  .video-overlay-text {
    width: 100%;
    max-width: 800px;
    text-align: center;
    margin: 0 auto;
    font-weight: 300;
    font-family: $font-heading;
    z-index: 99;

    .play-button {
      margin-bottom: 40px;
    }

    .heading, .subheading {
      text-transform: uppercase;
    }
    .heading {
      font-size: 40px;
      font-weight: 300;
      font-family: $font-heading;

      @media screen and (max-width: $mobile-small) {
        font-size: 17px;
      }
    }
    .subheading {
      font-size: 25px;
      @media screen and (max-width: $mobile-small) {
        font-size: 17px;
      }
    }
    p, p.paragraph {
      font-family: $font-body;
      margin-bottom: 30px;
    }
  }
  &.heroVideo {
    .video-overlay-text {
      padding-top: 0px;
    }
  }
}

.heroVideoSimpleModal {
  .modal-dialog {
    max-width: 100%;
    @include media-breakpoint-up(md) {
      max-width: 1050px;
  	}

  }
  .modal-content {
    background-color: $color-black;
    .modal-header {
      border-bottom: 0px solid $color-white;
      button {
        font-size: 25px;
      }
    }
    .modal-body {
      padding: 0;
    }
  }
}


.heroVideoStory {
  background-color: $color-black;
}
