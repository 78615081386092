.imageCarousel {
  .swiper-wrapper {
    .swiper-slide {
      font-family: $font-body;
      cursor: pointer;
      img {
        width: 100%;
        border-bottom: 10px solid $color-primary;
      }
      .caption {
        margin: 0 auto;
        margin-top: 25px;
        font-size: 25px;
        .caption-title {
          font-size: 25px;
          font-weight: bold;
          font-family: $font-heading;
          text-transform: uppercase;
        }
        p {
          font-size: 25px;
        }
        .readmore {
          color: $color-primary;
          text-transform: uppercase;
        }
      }
    }
  }
}

.swiper-button-next, .swiper-button-prev {
  &::after {
    font-family: $fontawesome;
    font-size: 60px;
    line-height: 1;
    opacity: 1;
    &:hover {
      opacity: 0.75;
    }

    @media screen and (max-width: $large-screens) {
      font-size: 45px;
    }
    @media screen and (max-width: $mobile) {
      font-size: 35px;
    }
    @media screen and (max-width: $mobile-small) {
      font-size: 30px;
    }
  }
}
.swiper-button-prev {
  &::after {
    content: "\f104";
  }
}
.swiper-button-next {
  &::after {
    content: "\f105";
  }
}
