header {
  background: transparent;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10000;

  @media screen and (min-width:$tablet-small) {
    top: 63px;
  }

  .navbar {
    padding: 0px;
    width: 100%;
    position: fixed;
    z-index: 100;
    top: 0;
    border-bottom: 3px solid $color-primary;

    .hide {
      display: none !important;
    }

    @media screen and (max-width:$tablet-small) {
      border-bottom: none;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      background-color: hsla(0, 0%, 100%, .85);

      @supports ((-webkit-backdrop-filter: blur(2em)) or (backdrop-filter: blur(2em))) {
        backdrop-filter: blur(27px);
        -webkit-backdrop-filter: blur(27px);
        background-color: hsla(0, 0%, 100%, .5);
      }
    }

    .navbar-toggler {
      border: none;
      width: 42px;
      height: 36px;
      font-size: 20px;
      padding: 0;
      margin: 13px;
      position: absolute;
      display: none;

      @media screen and (max-width:$tablet-small) {
        &.active {
          display: block;

          &[aria-expanded=true] span {
            background: none;
            border: 2px solid $color-primary;
            text-align: center;

            &::after {
              content: 'x';
              display: block;
              color: $color-primary;
              font-size: 25px;
            }
          }
        }
      }
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    .inner-bar {
      @include transition(250);

      @media screen and (max-width:$tablet-small) {
        width: 100%;
        position: fixed;
        z-index: 10;
        left: 0;
        background-color: hsla(0, 0%, 100%, .85);

        @supports ((-webkit-backdrop-filter: blur(2em)) or (backdrop-filter: blur(2em))) {
          backdrop-filter: blur(27px);
          -webkit-backdrop-filter: blur(27px);
          background-color: transparent;
        }

        border-bottom: 3px solid $color-primary;

        &.active {
          border: none;
        }
      }

      #site-branding {
        margin: 0 3rem;
        padding: 9px;
        z-index: 2;
        min-height: 67px;

        @media screen and (min-width:1680px) {
          z-index: 101;
        }

        .navbar-brand {
          width: 96px;
          margin: 0;

          img {
            display: block;
            width: 100%;
            height: 38.03px;
          }

          @media screen and (min-width:1680px) {
            position: absolute;
            top: 9px;
            left: calc(50% - 816px);
            z-index: 101;
          }
        }

        @media screen and (max-width:$large-screens) {
          margin: 0 0 0 1rem;
        }

        @media screen and (max-width:$tablet-small) {
          margin: 0 2rem 0 auto;
          padding: 9px 0;
          text-align: right;
        }
      }

      &.active {
        border-bottom: 3px solid transparent;
      }
    }

    .navbar-collapse {
      position: static;
      z-index: 2;
      top: 0;
      @include transition(200);

      @media screen and (max-width:$tablet-small) {
        visibility: hidden;
        pointer-events: none;
        background-color: hsla(0, 0%, 100%, .85);

        @supports ((-webkit-backdrop-filter: blur(2em)) or (backdrop-filter: blur(2em))) {
          backdrop-filter: blur(27px);
          -webkit-backdrop-filter: blur(27px);
          background-color: rgba(255, 255, 255, 0.5);
        }

        padding-left: 17px;
        padding-right: 17px;
        border-bottom: solid 5px $color-primary;
        position: fixed;
        height: 100%;
        width: 100%;

        &::-webkit-scrollbar {
          width: 10px;
        }

        &::-webkit-scrollbar-track {
          background-clip: content-box;
        }

        &::-webkit-scrollbar-thumb {
          background: $color-primary;
          height: 25px;
        }

        max-height: 0%;
        opacity: 0.25;
      }

      &.show {
        pointer-events: auto;
        opacity: 1;
        visibility: visible;
        max-height: 100%;
        padding-bottom: 10px;
        padding-top: 75px;
      }

      ul.navbar-nav {
        width: 100%;
        list-style: none;
        justify-content: space-evenly;
        align-items: center;
        padding: 0;

        @media screen and (max-width:$tablet-small) {
          align-items: flex-start;
        }

        li.nav-item.level-1 {
          height: 100%;

          @media screen and (max-width:$tablet-small) {
            height: auto;
          }

          &.nav-item {
            line-height: 1;
            color: white;

            @media screen and (max-width:$tablet-small) {
              line-height: initial;
              display: block;
              width: 100%;
              line-height: 20px;
            }

            &>a {
              display: flex;
              align-items: center;
              height: 100%;
              font-size: 20px;
              padding: 0 1vw;
              text-transform: uppercase;
              text-decoration: none !important;

              &.active {
                color: $color-primary;

                .child-menu-2 {
                  display: block;
                }
              }

              @media screen and (max-width:1184px) {
                font-size: 16px;
                padding: 0 .75vw;
              }

              @media screen and (max-width:$tablet-small) {
                font-size: 22px;
                padding: 15px 0;
              }
            }

            &.last {


              @media screen and (max-width: $tablet-small) {
                padding-bottom: 0;
                border-bottom: 0;
              }
            }

            &.active {
              display: block;
            }
          }
        }

        &.primary-nav {
          max-width: 860px;
          height: 100%;

          @media screen and (max-width:$tablet-small) {
            max-width: 100%;
            height: auto;
          }

          .nav-item {
            height: 100%;
            line-height: 1;

            @media screen and (max-width:$tablet-small) {
              height: auto;
            }

            a {
              text-decoration: none;
              color: $color-black;
              font-family: $font-heading;
              font-size: 17px;
              letter-spacing: 1px;

              @media screen and (max-width:$tablet-small) {
                display: flex;
                justify-content: space-between;
                font-size: 20px;
                display: block;
                border-bottom: solid 1px $color-gray;

                &::after {
                  font-family: $fontawesome;
                  content: "\f054";
                  background-size: 10px 20px;
                  background-position: center;
                  background-repeat: no-repeat;
                  width: 10px;
                  height: 20px;
                  font-weight: normal !important;
                }
              }

              @media screen and (max-width:$small) {
                font-size: 5vw;
              }

              &:hover {
                color: $color-dark-gray;
              }

            }

            &.level-1 {
              @media screen and (min-width: $tablet-small) {

                &:hover,
                &.active {
                  >a {
                    color: $color-dark-gray;
                    position: relative;

                    &::after {
                      content: '';
                      display: block;
                      width: calc(100% - 2vw);
                      border-bottom: 1px solid $color-dark-gray;
                      position: absolute;
                      bottom: 17px;
                    }
                  }
                }
              }

              & a {
                .summary {
                  display: none;
                }
              }
            }

            .child-menu-2 {
              padding: 20px;
              display: none;
              position: absolute;
              background-color: hsla(0, 0%, 100%, .85);

              @supports ((-webkit-backdrop-filter: blur(2em)) or (backdrop-filter: blur(2em))) {
                backdrop-filter: blur(27px);
                -webkit-backdrop-filter: blur(27px);
              }

              list-style: none;
              border-bottom: 2px solid $color-primary;
              margin-top:3px;

              @media screen and (max-width:$tablet-small) {
                background-color: transparent;
                border-bottom: none;
                backdrop-filter: none;
                -webkit-backdrop-filter: none;
              }

              a {
                display: block;
                font-size: 20px;
                text-transform: uppercase;
                padding: 10px 0 5px;
                border-bottom: 1px solid $color-black;

                @media screen and (max-width: $tablet-small) {
                  font-size: 22px;
                }
              }

              a:after {
                float: right;
              }

              @media screen and (max-width: $tablet-small) {
                position: relative !important;
                padding: 0;
              }
            }

            &.active {
              .child-menu-2 {
                display: block;
              }
            }

            &#page-33 {
              position: static;

              >a {
                white-space: nowrap;
              }

              &.active .child-menu-2 {
                display: none;
              }
            }


          }
        }
      }

    }

    .socialmedia-menu {
      display: none;

      @media screen and (max-width: $tablet-small) {
        display: block;
      }

      .social-media {
        padding: 17px 0 5px;
        justify-content: flex-start;

        li {
          margin: 0 18px 0 0;
        }
      }
    }

    .navbar-nav.quicklinks {


      background-color: rgba(0, 0, 0, .75);
      height: 100%;
      width: auto;
      justify-content: center;
      padding: 0 40px;
      max-width: 520px;

      @media screen and (max-width:1250px) {
        max-width: 480px;
      }

      @media screen and (max-width:1184px) {
        max-width: 420px;
      }

      @media screen and (max-width:1100px) {
        max-width: 400px;
      }

      @media screen and (max-width:$tablet-small) {
        background-color: transparent;
        border-left: none;
        height: auto;
        width: 100%;
        margin-top: 15px;
      }

      .nav-item.level-1 {
        text-align: center;

        @media screen and (max-width:$tablet-small) {
          color: $color-gray;
          width: 100%;
          text-align: left;
          padding: 0;
        }

        a {
          text-transform: none !important;
          white-space: nowrap;
          color: $color-white;
          font-size: 16px !important;
          position: relative;

          @media screen and (max-width: 1184px) {
            font-size: 12px !important;
          }

          @media screen and (max-width:$tablet-small) {
            color: $color-gray;
            border: none;
            font-size: 15px !important;
            padding: 0 0 15px !important;
          }

          &:hover {
            text-decoration: none;
            position: relative;

            &::after {
              content: '';
              display: block;
              width: calc(100% - 2vw);
              border-bottom: 1px solid $color-white;
              position: absolute;
              bottom: 17px;
            }

            @media screen and (max-width:$tablet-small) {
              color: $color-white;
            }
          }
        }

        &#page-588 {
          a:before {
            font-family: $fontawesome;
            content: "\f3c5";
            margin-right: 10px;
            font-weight: bold;
            font-size: 20px;
            margin-top: -5px;

            @media screen and (max-width:$tablet-small) {
              content: "";
              margin-right: 0;
            }
          }
        }

        &.last a {
          position: relative;
          font-weight: bold;

          @media screen and (min-width:$tablet-small) {
            &::before {
              content: '';
              display: inline-block;
              height: 20px;
              border-left: solid 1px $color-white;
              position: absolute;
              top: 20px;
              left: -13px;
            }
          }
        }
      }
    }
  }

  .back-arrow {
    border-bottom: solid 1px #727272;
    position: relative;
    height: 40px;
    display: block;
    padding-top: 10px;

    @media screen and (min-width:$tablet-small) {
      display: none;
    }

    &::after {
      font-family: $fontawesome;
      content: "\f053";
      font-size: 20px;
      display: block;
      height: 30px;
      width: 30px;
      color: $color-dark-gray;
      @include transition(250);
    }
  }

  .boats-wrapper {
    position: absolute;
    top: 66px;
    left: 0;
    width: 100%;
    padding: 20px 0;
    border-bottom: 3px solid $color-primary;
    display: none;
    background-color: hsla(0, 0%, 100%, .95);

    @supports ((-webkit-backdrop-filter: blur(2em)) or (backdrop-filter: blur(2em))) {
      background-color: rgba(255, 255, 255, .5);
      backdrop-filter: blur(27px);
      -webkit-backdrop-filter: blur(27px);
    }

    &.active {
      display: block;
    }

    @media screen and (max-width:$tablet-small) {
      top: auto;
      padding: 0;
      position: relative;

      @supports ((-webkit-backdrop-filter: blur(2em)) or (backdrop-filter: blur(2em))) {
        background-color: transparent;
        backdrop-filter: none;
        -webkit-backdrop-filter: blur(27px);
      }

      border-bottom: none;
    }

    a {
      color: $color-dark-gray;

      &:hover {
        color: $color-gray;
        text-decoration: none;
      }
    }

    p {
      font-size: 12px;

      @media screen and (max-width: $tablet-small) {
        display: none;
      }
    }

    .section-heading {
      font-size: 20px;
      margin: 0 0 5px 0;
      text-transform: uppercase;
      font-family: "Druk-Super", Arial, sans-serif;
    }

  }

  .boats-section {
    width: 100%;
    max-width: 950px;
    margin: auto;

  }

  .boats-row {
    list-style: none;

    @media screen and (min-width:$tablet-small) {
      width: 100%;
      max-width: 950px;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
    }

    @media screen and (min-width:$tablet-small) {
      .editions {

        .images-wrapper {

          position: relative;

          .boat-nav--link {
            display: block;
            position: absolute;

            @media screen and (max-width: $tablet-small) {
              position: static;
            }

            &:first-child {
              top: -10px;
              left: -15px;
            }

            &:nth-child(2) {
              top: 20px;
              left: 30px;
            }

          }
        }

        .boat-nav--info {
          margin-top: 150px;
        }

      }
    }

    .boat {
      border-bottom: 1px solid $color-dark-gray;
      margin: 25px 0 20px;

      @media screen and (min-width:$tablet-small) {
        border-bottom: none;
        width: 33%;
        padding: 0 20px 20px;
        text-align: center;

        a.boat-nav {

          &--build,
          &--compare {
            opacity: 0;
            transition: opacity 0.2s ease-in-out;
          }
        }

        &:hover {
          background-color: $color-white;
          transition: background-color 0.2s ease-in-out;

          a.boat-nav {

            &--build,
            &--compare {
              opacity: 1;
            }
          }
        }
      }
    }

    .boat-nav {
      &--image {
        display: block;
        width: 75%;
        max-width: 400px;
        margin-bottom: 15px;
        flex-basis: auto;

        @media screen and (min-width:$tablet-small) {
          margin-bottom: 0;
          height: 130px;
          width: 239px;
        }
      }

      &--info {
        margin-top: 40px;
        padding-right: 10px;

        @media screen and (min-width:$tablet-small) {
          margin-top: 10px;
          padding: 0;
        }

      }

      &--series {
        font-family: $font-subheading;
        font-size: 11px;

        @media screen and (max-width: $tablet-small) {
          width: 57px;
        }
      }

      @media screen and (min-width: $tablet-small) {
        font-size: 13px;
      }
    }

    &--title {
      font-size: 18px;
      font-family: "Druk-Super", Arial, sans-serif;

      @media screen and (min-width: $tablet-small) {
        font-size: 22px;
      }
    }
  }

  a.boat-nav {
    &--link {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      margin: 10px 0 0 0;
      padding: 0;
      border-bottom: none;

      @media screen and (min-width:$tablet-small) {
        flex-direction: column;
        align-items: center;
      }

      @media screen and (max-width:$tablet-small) {
        .edition-models {
          display: none;
        }
      }
    }

    &--build,
    &--compare {
      border-bottom: none;
      width: auto;
      display: inline-block;
      font-family: $font-subheading;
      font-size: 12px;
      text-transform: uppercase;
      padding: 0;

      &::after {
        display: none;
      }

      &:hover {
        color: $color-gray;
      }
    }

    &--compare {
      border-left: 1px solid $color-dark-gray;
      padding-left: 15px !important;
      margin-left: 15px;
      margin-bottom: 20px;

      @media screen and (min-width:$tablet-small) {
        margin-bottom: 0;
      }

      &:after {
        display: none !important;
      }
    }
  }
}

.boat-nav--title {
  font-size: 18px;
  font-family: "Druk-Super", Arial, sans-serif;

  @media screen and (min-width: $tablet-small) {
    font-size: 22px;
  }
}

.edition-models {
  display: flex;
  justify-content: center;

  .edition-model {
    box-sizing: border-box;
    padding: 0px 15px;
    margin-top: 0px;
    cursor: pointer;

    &:first-child {
      border-right: 0.5px solid $color-dark-gray;
      margin-right: 1px;
    }
  }

  @media screen and (max-width:$tablet-small) {
    display: none;
  }
}

.boat-nav--info.mobile {
  @media screen and (min-width:$tablet-small) {
    display: none;
  }
}

.edition-series {
  @media screen and (max-width:$tablet-small) {
    display: none;
  }
}

.edition-series.mobile {
  display: block;

  @media screen and (min-width:$tablet-small) {
    display: none;
  }
}

@media screen and (max-width:$tablet-small) {
  .images-wrapper {
    width: 100%;
  }

  .edition {
    width: 100%;
    display: flex;
  }

  .editions-desktop {
    display: none !important;
  }

  .boat-nav--link:nth-child(4) {
    //complete hackery
    border-top: 1px solid #1e272d;
    padding-bottom: -25px;
  }
}

@media screen and (min-width:$tablet-small) {
  .editions-mobile {
    display: none !important;
  }
}