/*************************************************************
   ** @FONT FACES
*************************************************************/

// @font-face declarations are in <head><style>

/*************************************************************
   ** FONT THEME STYLES
*************************************************************/

/***** DEFAULT FONTS *****/
@mixin font-appearance(){
	text-rendering: optimizeLegibility;
    font-feature-settings: 'kern';
    font-kerning: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@mixin default-font($color: $color-black) {
	@include font-appearance();
    font-family: $font-body;
    font-size: $base-font-size;
    font-weight:$normal;
    line-height: 1.3;
    @media screen and (max-width:$mobile){
        font-size: $base-font-size-mobile;
    }
}

@mixin default-anchor($color: $color-black, $hoverColor: $color-black) {
	@include font-appearance();
    font-family: $font-body;
    font-size: inherit;
    font-weight: $bold;
    text-decoration: underline;
    transition: all 0.35s;
    &:hover,
    &:focus{
        color:$hoverColor;
    }
}

@mixin default-heading($color: $color-black) {
	@include font-appearance();
    font-family:$font-heading;
    font-weight: $bold;
    line-height: 1.2;
}




/***** HEADING MIXINS *****/
@mixin h1($color: $color-black) {
		font-family: $font-heading;
    font-size: 55px;
    margin: 0.15em 0 0.35em;
		text-transform: uppercase;
		letter-spacing: .03em;
    @media screen and (max-width:$mobile){
        font-size:35px;
    }
}

@mixin h2($color: $color-black) {
		font-family: $font-heading;
    font-size: 45px;
    margin: 0.15em 0 0.35em;
    text-transform: uppercase;
		letter-spacing: .03em;
    @media screen and (max-width:$mobile){
        font-size:35px;
    }
		strong, b, span {
        margin-bottom: 10px;
        font-size:17px;
        text-transform: none;
        display: block;
        font-family: $font-subheading;
				letter-spacing: 0;
				@media screen and (max-width:$mobile){
		        font-size:13px;
		    }
    }
}

@mixin h3($color: $color-black) {
		font-family: $font-heading;
    font-size: 35px;
    margin: 0.1em 0 0.3em;
    letter-spacing: 1px;
		text-transform: uppercase;
		letter-spacing: .03em;
    @media screen and (max-width:$mobile){
        font-size:25px;
    }
}

@mixin h4($color: $color-black) {
		font-family: $font-subheading;
    font-size: 28px;
    margin: 0.1em 0 0.3em;
    @media screen and (max-width:$mobile){
        font-size:24px;
    }
}

@mixin h5($color: $color-black) {
		font-family: $font-subheading;
    font-size: 22px;
    margin: 0.05em 0 0.25em;
    @media screen and (max-width:$mobile){
        font-size:18px;
    }
}

@mixin h6($color: $color-black) {
    font-size: 18px;
    margin: 0.05em 0 0.25em;

    @media screen and (max-width:$mobile){
        font-size:16px;
    }
}



/***** HEADING CLASSES *****/
h1,
.font--h1 {
    @include h1($color-black);
}

h2,
.font--h2 {
    @include h2($color-black);
}

h3,
.font--h3 {
    @include h3($color-black);
}

h4,
.font--h4 {
    @include h4($color-black);
}

h5,
.font--h5 {
    @include h5($color-black);
}

h6,
.font--h6 {
    @include h6($color-black);
}




/***************************************
    * THEME BASED CLASSES & MIXINS
***************************************/
// The mixins/classes in this section are just examples of mixins to create for your theme
// Identify common text elements across your theme like section headings that you can create a mixin/class for

/***** THEME MIXINS *****/
@mixin subhead--one($color: $color-black) {
    font-size:11px;
    font-weight:$bold;
    @media screen and (max-width:$mobile){
        font-size:13px;
    }
}


@mixin section__title($color: $color-black) {
    text-transform: uppercase;
    text-align:center;
    margin:0 0 0.5em;
}

@mixin body-link($color: $color-black, $hoverColor: $color-primary) {
	color: $color;
	border-bottom: solid 2px $color;
	text-decoration: none;
	@include transition(250);

	&:hover,
	&:focus {
		color: $hoverColor;
		border-bottom-color: $hoverColor;
		text-decoration: none;
	}
}

/***** THEME CLASSES *****/
// SUBHEADS
.subhead--one{
    @include subhead--one($color-black);
}


// SECTION TITLES
.section__title{
    @include section__title($color-black);
}

.theme-color-black {
    color: $color-black;
}
.theme-color-white {
    color: $color-white;

    table {
        color: $color-white;
    }

    input[type=text], input[type=tel], input[type=email], select, textarea, .form-control {
        color: #a3a3a3;
    }

}


/***************************************
    * STANDARD ELEMENTS & CLASSES
***************************************/

a {
		color: $color-primary;
    @include transition(250);
}

p,
.font--p{
    //@include default-font($color-black);
    margin:0.1em auto 0.3em;
}
p.paragraph {
	font-family: $font-body;
}

b,
strong {
    font-weight: $bold;
}

iframe{
    max-width: 100%;
}

img {
    max-width: 100%;
    @media screen and (max-width: $mobile) {
        display: block;
    }
}

ul,
ol{
    padding-left: 20px;
}
ul{
    list-style:disc outside;
}
ol{
    list-style:decimal outside;
}
ul ul,
ol ol {

}
ul li,
ol li {
    line-height: 1.65;
}



blockquote {
    margin: 0 auto;
    padding: 120px 0 104px;
    @media screen and (max-width: $mobile) {
        padding: 40px 0 24px; 
    }
    width:100%;
    max-width: $container_sm;
    p {
        @include font-appearance();
        font-family: $font-subheading;
        font-style:italic;
        text-align: center;
        margin: 0;
        letter-spacing: 0.025em;
        font-size:44px;
        padding-bottom: 10px;
        @media screen and (max-width: $mobile) {
            font-size: 24px;
        }
        b, strong, cite {
            font-size: 22px;
            text-transform: uppercase;
            font-family: $font-heading;
            font-style: normal;
        }
    }
}
.text-block blockquote p {
    padding: 10px;
}

cite {
    display: block;
    font-size: 65%;
    text-align: center;
    font-style: normal;
    margin-top: 1em;
}

address {
    font-style: normal;
}

sub,
sup {
    font-size: 60%;
    line-height: 0;
    position: relative;
}

sub {
    bottom: -0.2em;
    vertical-align: sub;
}

sup {
    top: -0.75em;
    vertical-align: super;
}
.lowercase {
    text-transform: lowercase;
}
.font-smaller {
    font-size: smaller;
}
