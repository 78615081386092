.rotatable-feature-highlight {
  border-bottom: $section-break;
  padding: 60px 0;
  .inner-circle {
    background-color: $color-primary;
    border-color: $color-white;
  }
  &.theme-light .swiper-slide {
    background: $color-white;
    height: auto;
  }
  &.theme-dark .swiper-slide {
    background: $color-primary;
    height: auto;
  }
  &.theme-light {
    background: $color-white;
    .model-rotator
      .modelSpotlight__images
      .boat-spotlight-image
      .modelSpotlight__hotspots
      .hotspot_trigger__wrapper
      .hotspot__trigger {
      &::before,
      &::after {
        background-color: $color-primary;
      }
      .inner-circle {
        background-color: $color-white;
        border-color: $color-primary;
      }
    }
  }
  &.theme-dark {
    background: $color-primary;
    .model-rotator
      .modelSpotlight__images
      .boat-spotlight-image
      .modelSpotlight__hotspots
      .hotspot_trigger__wrapper
      .hotspot__trigger {
      &::before,
      &::after {
        background-color: $color-white;
      }
      .inner-circle {
        background-color: $color-primary;
        border-color: $color-white;
      }
    }
  }
  @media screen and (max-width: $mobile) {
    padding: 30px 0;
  }

  .feature-highlight-title {
    //h2
    width: auto;
    padding: 0 15px;
    @media screen and (min-width: $tablet) {
      position: absolute;
      top: 0;
      z-index: 2;
      padding: 0;
    }

    span {
      font-size: 18px;
      margin-bottom: 10px;
    }
  }

  .model-rotator {
    position: relative;
    @media screen and (max-width: $tablet) {
      max-height: unset;
    }

    .modelSpotlight__images {
      height: 100%;
      width: 100%;
      position: relative;
      margin: 0 25px;
      top: 0;

      .feature-hightlight-flex {
        display: flex;
        flex-direction: column-reverse;
      }
      .boat-spotlight-image {
        position: relative;
        margin: 0 auto;
        width:100%;
        height:100%;
        &.active {
          opacity: 1;
          visibility: visible;

          .modelSpotlight__hotspots {
            visibility: visible;
          }
        }
        .image-with-hotspots {
          position: relative;
          overflow: hidden;
          width: 100%;
          max-width: 1010px;
          margin: auto;
          aspect-ratio: 1010 / 550;
          img {
            position: absolute;
            display:block;
            -webkit-user-drag: none !important;
            -webkit-user-select: none !important;
            -webkit-touch-callout: none !important;
            width: 100%;
            max-width: 1010px;
          }
        }
        .modelSpotlight__hotspots {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          .hotspot_trigger__wrapper {
            .hotspot__trigger {
              position: absolute;
              display: flex;
              justify-content: center;
              align-items: center;
              z-index: 5;
              width: 35px;
              height: 35px;
              line-height: 0;
              @include transition(150);


              &::before {
                content: "";
                position: absolute;
                width: 3px;
                height: 80%;
                @media screen and (min-width: $mobile) {
                  height: 100%;
                }
                margin: 0 auto;
              }

              &::after {
                content: "";
                position: absolute;
                width: 80%;
                @media screen and (min-width: $mobile) {
                  width: 100%;
                }
                height: 3px;
                top: 50%;
                transform: translateY(-50%);
              }

              .inner-circle {
                z-index: 1;
                @include transition(150);
                display: block;
                border: solid;
                border-width: 3px;
                width: 15px;
                height: 15px;
                @media screen and (min-width: $mobile) {
                  width: 25px;
                  height: 25px;
                }
              }

              &:hover {
                text-decoration: none;
                width: 45px;
                height: 45px;
                margin: -5px;

                .inner-circle {
                  width: 30px;
                  height: 30px;
                }

                @media screen and (max-width: $mobile) {
                  // width: 40px;
                  // height: 40px;
                  // padding: 6px;
                  // margin: -9px;
                  // border-width: 2px;
                }
              }
              &.active {
                .inner-circle {
                  background-color: transparent;
                }
              }
            }
          }

          .hotspot__wrapper {
            width: 100%;
            max-width: 400px;
            height: 100%;
            position: absolute;
            opacity: 0;
            visibility: hidden;
            z-index: 20;

            @media screen and (max-width: $mobile) {
              padding: 30px;
            }
            @media screen and (max-width: $mobile-small) {
              padding: 10px;
              top: 4vw;
            }

            &.active {
              opacity: 1;
              visibility: visible;
              .hotspot__content {
                opacity: inherit;
              }
            }

            &.position-left {
              left: 100px;
              @media screen and (max-width: $mobile) {
                left: 0;
              }
              .hotspot__content {
                text-align: right;

                .close-wrapper {
                  right: -20px;
                }
              }
            }
            &.position-right {
              right: 100px;
              @media screen and (max-width: $mobile) {
                right: 0;
              }
              .hotspot__content {
                text-align: left;

                .close-wrapper {
                  left: -20px;
                }
              }
            }

            .hotspot__content {
              position: relative;
              opacity: 0;
              transition: opacity 250ms;
              padding: 30px;

              @media screen and (max-width: $tablet) {
                text-align: center !important;
              }
              @media screen and (max-width: $small) {
                background-color: $color-dark-gray;
                padding: 30px 20px;
                text-align: center !important;
              }
              .close-wrapper {
                position: absolute;
                top: -20px;
                z-index: 100;

                @media screen and (max-width: $tablet) {
                  left: -15px;
                  top: -15px;
                  right: unset !important;
                }

                .hotspot__close {
                  width: 40px;
                  height: 40px;
                  padding: 6px;
                  border-style: solid;
                  border-width: 4px;
                  font-size: 20px;
                  @include transition(150);
                  display: inline-block;

                  .fas {
                    width: 100%;
                    height: 100%;
                    display: inline-block;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    @include transition(150);
                    &::after {
                      content: "x";
                      font-style: normal;
                      font-weight: bold;
                    }
                  }

                  &:hover {
                    text-decoration: none;
                    padding: 10px;
                    width: 50px;
                    height: 50px;
                    margin: -5px;

                    .fas {
                      background-color: $color-white;
                      color: $color-primary;
                    }
                  }
                }
              }

              .hotspot-heading {
                font-family: $font-subheading;
                font-size: 18px;

                @media screen and (max-width: $mobile-small) {
                  font-size: 15px;
                }
              }
              .hotspot-copy {
                font-family: $font-body;
                font-size: 18px;

                @media screen and (max-width: $mobile-small) {
                  font-size: 15px;
                }
              }
            }
          }
        }
      }
    }
  }
  .swiper-button-prev,
  .swiper-button-next {
    top: 50%;
    //z-index: 1001;
  }
  .feature-highlight-description {
    max-width: 1000px;
    padding: 0 25px;
    @media screen and (min-width: $tablet) {
      min-width: 1000px;
    }
    margin: 0 auto;
    flex-basis: auto;
    text-align: left;

    h5 {
      padding-top: 40px;
      font-family: $font-subheading;
      font-size: 18px;
      font-weight: $bold;
      &:empty {
        display: none;
      }
    }

    p {
      font-family: $font-body;
      &:empty {
        display: none;
      }
    }
  }

  &.theme-dark {
    .hotspot__content {
      background-color: $color-white;
      color: $color-black;
    }
    .hotspot__trigger {
      &::before,
      &::after {
        background-color: $color-white;
      }
      .inner-circle {
        border-color: $color-white;
        background-color: $color-primary;

        &.active {
          background-color: $color-white;
        }
      }
    }
    .hotspot__close {
      border-color: $color-white;
      background-color: $color-primary;
      .fas {
        color: $color-white;
      }
      &.active {
        background-color: $color-white;
        .fas {
          color: $color-primary;
        }
      }
    }
    .modelSpotlight__tabs li a {
      border-color: $color-white;
      background-color: $color-primary;
      &.active {
        background-color: $color-white;
      }
    }
  }
  &.theme-light {
    .hotspot__content {
      background-color: rgba(235, 235, 235, 0.95);
      color: $color-black;
    }
    .hotspot__trigger {
      &::before,
      &::after {
        background-color: $color-primary;
      }
      .inner-circle {
        border-color: $color-primary;
        background-color: $color-white;

        &.active {
          background-color: $color-primary;
        }
      }
    }
    .hotspot__close {
      border-color: $color-primary;
      background-color: $color-white;
      &.active {
        background-color: $color-primary;
      }
    }
    .modelSpotlight__tabs li a {
      border-color: $color-primary;
      background-color: $color-white;
      &.active {
        background-color: $color-primary;
      }
    }
  }
}