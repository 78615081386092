.profileNavigation {
  max-width: 800px;
  .row {
    [class*="col-"]:first-child {
      margin-bottom: 10px;
    }
    .prev-profile, .next-profile {
      display: block;
      width: 100%;
      text-align: center;
      padding: 10px 5px;
      background-color: $color-primary;
      span {
        font-family: $font-heading;
        text-transform: uppercase;
        color: $color-white;
        transition: $trans;
        font-size: 22px;
        i {
          padding: 0 10px;
        }
      }
      &:hover {
        text-decoration: none;
      }
    }
  }
}
