.vid-section {
  width: 100%;
  border-bottom: $section-break;
  padding: 0 !important;

  a {
    color: $color-black;

    &:hover {
      text-decoration: none;
    }
  }

  .vid-wrapper {
    @media screen and (max-width: $tablet-small - 1px) {
      padding: $site_container_padding;
      display: flex;
      flex-wrap: wrap;

      >* {
        flex-basis: 100%;
      }
    }

    @media screen and (min-width: $tablet-small) {
      max-width: unset;
      padding-bottom: 56.25%;
      overflow: hidden;
    }
  }

  .vid_heading--sm {
    @media screen and (min-width: $tablet-small) {
      display: none;
    }

    h3 {
      @include h1;
      text-align: center;
    }
  }

  .vid_image {
    display: block;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    position: relative;

    @media screen and (max-width: $tablet-small - 1px) {
      margin-bottom: 20px;
    }

    @media screen and (min-width: $tablet-small) {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      padding: unset;
    }

    img {
      position: relative;
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;

      @media screen and (max-width: $tablet-small) {
        position: absolute;
      }
    }

    .play-button {
      z-index: 1;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      right: 0;

      @media screen and (min-width: $tablet-small) {
        display: none;
      }
    }
  }

  .vid_text {
    @media screen and (min-width: $tablet-small) {
      z-index: 1;
      width: 66.666%;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      background: $color-white;
      max-width: 950px;
      padding: $site_container_padding;
      display: flex;
      gap: 40px;
      justify-content: flex-end;
      align-items: center;

      &.top {
        top: 20%;
      }

      &.bottom {
        top: 80%;
      }
    }

    .play-button {
      flex-shrink: 0;

      @media screen and (max-width: $tablet-small - 1px) {
        display: none;
      }
    }

    .text-wrapper {
      h3 {
        .heading {
          @include h1;

          @media screen and (max-width: $tablet-small - 1px) {
            display: none;
          }
        }

        .subheading {
          display: block;
          @include h5;
          text-transform: none;
          letter-spacing: 0;
        }
      }

      a {
        @include body-link;
        font-family: $font-subheading;
      }
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    margin: 0;
  }

  .swiper-button-prev {
    left: 40px;

    @media screen and (max-width: $large-screens) {
      left: 20px;
    }

    @media screen and (max-width: $mobile-small) {
      left: 0;
    }
  }

  .swiper-button-next {
    right: 40px;

    @media screen and (max-width: $large-screens) {
      right: 20px;
    }

    @media screen and (max-width: $mobile-small) {
      right: 0;
    }
  }

  .video-grid-slider {
    width: 90%;
    max-width: 90%;
    margin: 0 auto;

    @media screen and (max-width: $mobile) {
      width: 83%;
      max-width: 83%;
    }

    .swiper-wrapper {
      .swiper-slide.video-slider-content {
        .video-thumb-container {
          position: relative;
          max-height: 400px;

          @media screen and (max-width: $mobile) {
            height: 250px;
          }

          img {
            display: block;
            object-fit: cover;
            width: 100%;
            height: 100%;
            object-position: center;
            aspect-ratio: 16/9;

            @media screen and (min-width: $tablet) {
              aspect-ratio: 1/1;
            }
          }

          .play-button-div {
            text-align: center;
            margin: 0 auto;
            position: absolute;
            width: 5vw;
            height: 5vw;
            min-width: 75px;
            min-height: 75px;
            max-width: 100px;
            max-height: 100px;
            top: 40%;
            left: 0;
            right: 0;

            .play-button {
              width: 100%;
              height: 100%;
              transform: none;
              margin: 0;
            }
          }

          .video-title {
            text-align: left;
            text-transform: uppercase;
            margin-top: 26px;
            font-family: $font-heading;
            font-size: 20px;
          }
        }

        .video-info {
          opacity: 0;

          .video-title {
            font-family: $font-heading;
            font-size: 16px;
            margin-top: 12px;
            text-transform: uppercase;

            @include media-breakpoint-up(md) {
              font-size: 25px;
            }
          }

          .video-caption {
            font-family: $font-body;
            font-size: 15px;
            margin-top: 10px;
          }
        }
      }
    }
  }
}

body.modal-open {
  header {
    z-index: 1;
  }
}

#video-modal {
  &.show {
    display: flex !important;
    align-items: center;
  }

  .modal-dialog {
    min-width: 95%;

    @include media-breakpoint-up(md) {
      min-width: 75%;
      max-width: 1050px;
    }

    .modal-content {
      background-color: black;

      .modal-header {
        border: none;
        padding: 0;

        .close {
          font-size: 2em;
          padding: 15px;
          margin-right: 0;
        }
      }

      .modal-body {
        padding: 0;
      }
    }
  }
}

.video-grid {
  padding: 50px 0;
  border-bottom: $section-break;

  .section-title {
    text-align: center;
    margin-bottom: 15px;

    @include media-breakpoint-up(md) {
      margin-bottom: 30px;
    }
  }

  .swiper-pagination {
    padding-top: 20px;

    @include media-breakpoint-up(md) {
      padding-top: 50px;
    }
  }

  .video-grid-slider {
    width: 80%;
    max-width: 80%;

    @media screen and (min-width: $tablet) {
      width: 90%;
      max-width: 90%;
    }

    margin: 0 auto;
  }

  .video-thumb-container {
    position: relative;
    max-height: 400px;

    img {
      display: block;
      object-fit: cover;
      width: 100%;
      height: 100%;
      object-position: center;
      aspect-ratio: 16/9;

      @media screen and (min-width: $tablet) {
        aspect-ratio: 1/1;
        max-height: 400px;
      }
    }

    .play-button-div {
      text-align: center;
      margin: 0 auto;
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    .video-modal-button {
      display: block;
      width: 100%;
      height: 100%;

      &:hover {
        .play-button {
          background-color: $color-white;
          border-color: $color-black;

          .square {
            background-color: $color-black;
          }
        }
      }
    }
  }

  .video-info {
    opacity: 1;

    .video-title {
      font-size: 18px;
      font-family: $font-subheading;
      margin-top: 20px;
    }

    .video-caption {
      font-size: 15px;
      margin-top: 10px;
    }
  }
}