#dealer-locator {

    .site-frame {
      padding:0 5% 30px;
      width: 100%;
      max-width: $container_sm;
      margin: 0 auto;
        @include media-breakpoint-up(md) {
          padding: 0 30px 80px;
        }
        .locator-form {
          padding-top:30px;
          @include media-breakpoint-up(md) {
          padding-top: 60px;
          }
        }
        .form-row {
            margin: 36px 0 53px;

            .btn-submit {
              height: 46px;
              display: block;
              margin: 12px auto 0 auto;
            }
            @include media-breakpoint-up(sm) {
              .form-group {
                padding:0;
                input.input-text {
                  display: inline-block;
                  width: 70%;
                  border:1px solid $color-black;
                  position: relative;
                  top: -3px;
                }
                .btn-submit {
                  width: 28%;

                  display: inline-block;
                  padding: 0;
                }
              }
            }

        }
        .dealer-result-list {
          h2 {
            font-family: $font-subheading;
            font-size: 16px;
          }
        }
    }
}
.dealer-list-wrapper {
    .btn-submit {
        margin-bottom: 10px;
    }
}
