/* Footer Styles */
.footer-main {
  background-color: $color-white;
	text-align: center;
	font-size: 15px;
	padding-bottom: 42px;
	.container {
		.footer-menu {
			max-width: 1024px;
			margin: auto;
			display: flex;
			flex-flow: row wrap;
			justify-content: center;
			.link {
				whitespace: nowrap;
				min-width: 250px;
				max-width: 33%;
				text-align: left;
				a{
					font-size: 16px;
					display: block;
					color: $color-dark-gray;
				}
				@media screen and (max-width: $mobile){
					a{
						margin-top: 15px;
						margin-bottom: 15px;
					}
				}
				.footer-menu-secondary {
					.sub-link {
						margin: 0 1.5rem  0;
					}
					a {
						color: $color-gray;
					}
				}
			}

			.footer-menu-secondary {
				.sub-link {
					margin: 0;
					padding: 0;
					a {
						color: $color-gray;
					}
				}
			}
			ul {
				list-style: none;
				padding-left: 0;
			}
			a {
				text-decoration: none;
			}
		}
		@media screen and (max-width:$mobile-small) {
			padding: 0 20px;
		}
	}
	.footer-menu-secondary {
		li {
			font-family: $font-body;
			text-transform: capitalize;
			@media screen and (max-width:$mobile) {
				margin: 3.571rem 0 0;
				padding-right: 5.714rem;
			}
		}
		@media screen and (max-width:$mobile) {
			padding-bottom: 3.571rem;
			text-align: left;
			padding: 0;
		}

	}
	@media screen and (max-width: 555px){
		.sm-offset{
			margin-top: -15px;
			margin-left: 42px;
			color: $color-gray;
		}
	}
	.social-media {
		margin-top: 60px;
		@media screen and (max-width:$mobile) {
			margin-top: 4.286rem;
		}
		li {
			margin:0 1rem;
			a {
				width: auto;
				max-width: 27px;
				svg {
					height: auto;
				}
				&.icon-facebook {
					max-width: 27px;
				}
				&.icon-youtube {
					padding-top: 2px;
				}
			}
		}
	}
	.logos {
		margin: 1rem 0 2rem ;
		display: inline-block;
		a {
			height: 23px;
			width: 80px;
			display: block;
		}
	}

	.copyright {
		font-size: 12px;
		color: $color-gray;
		@media screen and (max-width:$mobile) {
			font-size: 10px;
		}
	}

}
