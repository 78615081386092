.heroCinematicDetail {
  min-height: 500px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  position: relative;
  @include media-breakpoint-up(lg) {
    min-height: 800px;
  }
  
  .inner-content {
    position: absolute;
    width: 100%;
    z-index: 2;

    .hero-text-wrapper {
      align-items: center;
      .hero-text {
        h1 {
        }
      }
    }
    
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: black;
    opacity: 0.25;
    z-index: 0;
  }
  video {
    background-size: cover;
    background-position: center;
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.heroCinematicDetailText {
  .HCDtext {
    max-width: 1200px;
    margin: 0 auto;
    padding: 30px;
    @include media-breakpoint-up(md) {
      padding: 60px 30px;
    }
    h3 {
      font-size: 40px;
      font-family: $font-heading;
      line-height: 1.1666;

      @media screen and (max-width: $mobile) {
        font-size: 20px;
      }
    }
  }
}
