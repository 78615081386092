.eventLocation {
  .container {
    max-width: 800px;
    margin: 0 auto;
    .event-heading, .event-keynote {

    }
    .event-heading {
      font-size: 28px;
      font-family: $font-heading;
      text-transform: uppercase;
      margin-bottom: 20px;

    }
    .event-keynote {
      font-family: $font-body;
      font-size: 22px;
      margin-bottom: 40px;
    }
    .col-md-6 {
      text-align: center;
      .event-name {
        font-family: $font-heading;
        font-size: 20px;
        text-transform: uppercase;
        margin-bottom: 20px;
      }
      .event-date {
        font-family: $font-body;
        font-size: 20px;
      }
      ul {
        list-style: none;
        li {
          span.label {
            font-family: $font-heading;
            color: $color-primary;
            font-size: 20px;
            margin-right: 7px;
            text-transform: uppercase;
          }
          span.value {
            font-family: $font-body;
            font-size: 20px;

          }
        }
      }
    }
  }
}
