.imagePostArticle {
  padding: 5rem;
  .container {
    max-width: 130rem;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    padding: 4rem;
    .row {
      .exploreGear-content {
        background-color: $color-black;
        padding: 4rem;
        font-family: $font-heading;
        text-transform: uppercase;
        .section-title {
          text-align: left;
        }
        .subheading {
          color: $color-primary;
          font-size: 2rem;
          margin-bottom: 2rem;
        }
        p.paragraph {
          font-family: $font-body;
          font-size: 18px;
          line-height: 1.4;
          text-transform: none;
          margin-bottom: 2rem;
        }
        .readmore {
          // margin-top: 2rem;
        }
      }
    }
  }

}
