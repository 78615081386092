.formTabs {
  clear: both;
}
.compare {
  clear: both;
  padding-top: 20px;
  border-bottom: $section-break;
  @media screen and (min-width: $tablet) {
    padding-top: 60px;
  }

  .remove-boat, .remove-compare {
    span {
      display: inline-block;
      border: 1px solid $color-primary;
      border-radius: 100%;
      width:17px;
      height: 17px;
      position: relative;
      top: -4px;
      margin-right:5px;
      transition: all 0.3s ease-in-out;
      &::after {
        font-family: $font-body;
        content: "X";
        display: block;
        position: relative;
        top: 2px;
        left: 1px;
        left: 3px;
        font-size: 11px;
        font-weight: bold;
        color: $color-primary;
      }
    }
  }
  .remove-boat:hover span {
    background-color: $color-primary;
    &::after {
      color: white;
    }
  }

  #table-text {
    padding: 20px 10px;
    p {
      font-size: 25px;
      margin-bottom: 10px;
    }
  }
  .compare-header {
    text-align: left;

    p {
      font-size: 17px;
      margin-bottom: 10px;
      font-family: $font-subheading;
    }
    button.btn {
      @include ctaButton(19px);
      margin: 20px 0;
      opacity: 1;
      &:disabled {
        @include ctaButtonWhite(19px);
        opacity: 1;
      }
    }
  }
  .compare-submit-wrapper {
    border-bottom: $section-break;
    padding-bottom: 40px;
    text-align: center;
    @media screen and (min-width: $tablet-small) {
      padding-bottom: 130px;
    }
  }
  .boat-grid {
    margin:0;
    @media screen and (min-width: $tablet-small) {
      
    }
    .boat-grid-item {
      text-align: left;
      padding: 20px 0;
      transition: $trans;

      &:hover {
        cursor: pointer;
      }

      h5 {
        font-family: $font-heading;
        font-weight: 700;
        font-size: 25px;
        text-transform: uppercase;
        span {
          display: block;
          font-family: $font-subheading;
          font-size: 17px;
        }
      }
      img {
        transition: $trans;
        padding: 10px 10px 18px 10px;
        margin: 0 auto;
        max-width: 500px;
        width: 90%;
      }
      label {
        @include ctaButton();
        padding:.513rem 1.125rem .313rem;
      }
      .remove-compare {
        display: none;
        @include ctaButtonWhite();
        padding:.513rem 1.125rem .313rem;
        
        
        &:hover {
          background-color: $color-primary;
          color: white;
          span {
            border-color:white;
            &::after {
              color:white;
            }
          }
        }
      }
      input {
        display: none;
      }
      &.is-checked {
        img {
          opacity: 0.7;
        }
        label {
          display: none;
        }
        .remove-compare {
          display: inline-block;
        }
      }
    }
    &.mini {
      .boat-grid-item {
        border-bottom: none;
        flex: 0 0 33.33333%;
        max-width: 33.33333%;

        @media screen and (min-width: $mobile) {
          flex: 0 0 25%;
          max-width: 25%;
        }
        @media screen and (min-width: $tablet) {
          flex: 0 0 16%;
          max-width: 16%;
        }
        img {
          display: none;
        }
        h5 {
          font-size: 17px;
        }
        label {
          font-size: 17px;
          // display: flex;
          // align-items: center;
          // justify-content: center;

          .checkbox {
            top: 0;

            @media screen and (max-width: $mobile) {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }
  }
  .boat-table-container {
    width: 100%;
    left: 15px;
    display: none;
    transition: $trans;
    &.is-submit {
      display: block;
    }
    margin-top: 60px;
    margin-bottom: 40px;
    display: none;
    min-height: 1100px !important;
    div.boat-row {
      text-transform: uppercase;
      text-align: left;
      padding: 0;
      min-height: 950px;

      .remove-boat, .add-boat {
        margin: 0 0 5px 15px;
        text-transform: none;
        color: $color-gray;
        display:inline-block;
        font-size: 15px;
        cursor: pointer;
        .fas {
          color: $color-primary;
          margin-right: 5px;
        }
      }
      .remove-boat {
        float: left;
      }
      .add-boat {
        float: right;
      }
      .boat-select {
        width: 90%;
        margin: 0 auto;
        height: 45px;
        font-size: 15px;
      }
      img {
        width: 100%;
        padding: 20px 15px;
        margin: 0 auto;
      }
      h4 {
        font-family: $font-heading;
        font-size: 25px;
        margin-bottom: 25px;
        span {
          display: block;
          font-family: $font-subheading;
          font-size: 17px;
          color:$color-primary;
        }
      }
      .spec {
        min-height: 58px;
        font-size: 15px;
        padding: 10px 5px;
        &:nth-child(even) {
          background-color: #E0E0E0;
        }
        .td-label {
          color: $color-black;
          font-family: $font-subheading;
        }
        .td-content {
          color: $color-black;
        }
      }
      
      .boat-link, .build-link {
        padding: 6px 0;
        a {
          min-width: 145px;
          margin: 0 auto;
          display: inline-block;
        }
      }
      .boat-link {
        padding-top: 30px;
      }
    }
    // table.table {
    //   tbody {
    //     tr.boat-row {
    //       display: block;
    //       float: left;
    //       td {
    //         display: block;
    //         img {
    //           max-width: 150px;
    //         }
    //       }
    //     }
    //   }
    // }
  }
}
