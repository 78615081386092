.profileInfo {
  padding-top: $site-container-padding;
  padding-bottom: $site-container-padding;
  .profileHeading {
    font-family: $font-heading;
    font-size: 35px;
    text-transform: uppercase;
  }
  .row {
    margin-bottom: $site-container-padding;
    .profile-pic {
      text-align: center;
      @include media-breakpoint-down(md) {
        max-width: 400px;
        &:first-child, &:last-child {
          display: none;
        }
      }
      img {
        margin: 0 auto;
        @include media-breakpoint-up(md) {
          max-width: 350px;
        }
      }
    }
    .profile-info {
      ul {
        list-style: none;
        padding: 0;
        font-size: 20px;
        @include media-breakpoint-up(md) {
          column-count: 2;
        }
        li {
          margin-bottom: 6px;
          @include h6;
          .question {
            font-family: $font-subheading;
            color: $color-primary;
            text-transform: uppercase;
          }
          .answer {
            font-family: $font-body;
          }
        }
      }
    }
  }
}


.profile-navigation {
  ul {
    list-style: none;
  }
}
