.form-subheading {
  font-family: $font-body;
  margin-bottom: 20px;
  opacity: 0;
  backface-visibility: hidden;
}


.form-notification {
  border: solid 1px $color-gray;
  border-radius: 5px;
  padding: 25px;
  max-width: 600px;
  margin: 0 auto;
  h3 {
    font-family: $font-heading;
    margin-bottom: 10px;
    color: $color-primary;
    line-height: 1.5;
  }

}

form {
  width: 100%;
  .container {
    max-width:$container_sm ;
  }
  .form-band {
    background-color: $color-primary;
    width: 100%;
    padding:20px 0;
    margin-bottom: 15px;
    .form-check-label {
      color: $color-white;
    }
    .form-row {
      margin:10px -10px;
      @media screen and (max-width:$mobile){
        margin: 10px 0;
      }
    }
  }
  .form-row {
    //margin-bottom: 25px;
    margin: 10px 0;
    .form-group {
      padding: 0 10px;
      margin-bottom: 0;
      align-content: flex-start;
      .ui-textinput {
        width: 100%;
      }
      input[type=text], input[type=tel], input[type=email], select, textarea {
        font-family: $font-body;
        font-size: 12px;
        padding: 12px;
        width: 100%;
        border: none;
        border-radius: 0;
        height: 46px;

        &::placeholder {
          color: $color-gray;
        }

        &:focus,
        &:focus-visible {
          border-radius: 0;
        }
      }
      select {
        height: 46px;
      }
      textarea {
        height: 90px;
      }
    }
  }
  .inputs-container {
    background-color: $color-primary;
    .form-row {
      //margin-top: 25px;
    }
  }
  .form-buttons {
    width: 100%;
    max-width:1233px;
    padding: 0 10px;
    margin:20px auto 0;
    text-align: left;
    @media (max-width: $mobile) {
    text-align: center;
    }
  }

  @media (max-width: $mobile) {
    .form-row {
      .form-group {
        padding: 0 5px 5px;
      }
    }
    .form-buttons {
      margin-top: 0;
    }
  }
}

.malibu-theme {
  .form-group {
    -ms-flex: 1 1 auto!important;
    flex: 1 1 auto!important;
    align-content: flex-start;

    input[type=text], input[type=tel], input[type=email], select, textarea {
      border: 1px solid $color-gray;
      font-size: 15px;
      background-color: transparent;
    }
    input[type=text], select {
      height: 46px;
    }

    textarea {
      height: 90px;
    }
  }
}

.formTabs {
  border-bottom: $section-break;
  padding: 20px 0px 20px 0px;
  @media (min-width: $mobile) {
    padding: 40px 0px 40px 0px;
  }
  .section-title {
    margin-bottom: 0;
    padding-left: 10px;
  }
  p {
    margin: 7px 0 0 0;
    padding-left: 10px;
  }
  .nav-tabs {
    margin: 0px auto 20px auto;
    @include media-breakpoint-up(md) {
      margin: 20px auto 20px auto;
    }
    @include media-breakpoint-up(lg) {
      margin: 40px auto 40px auto;
    }
    .nav-item {
      padding: 0.5rem 1rem;
      @include media-breakpoint-up(md) {
        padding: 0.5rem 4rem;
      }
      .nav-link {
        color: $color-black;
        &:active {
          color: $color-primary;
        }
      }
    }
  }
  .tab-content {
    width: 100%;
    .tab-pane {
      form {
        .form-row {
          margin: 0;
          @include media-breakpoint-up(md) {
            margin: 10px 0;
          }
          width: 100%;
          .form-group {
            -ms-flex: 1 1 auto!important;
            flex: 1 1 auto!important;

            input[type=text], input[type=tel], input[type=email], select, textarea {
              border: none;
              font-size: 12px;
              //background-color: transparent;
            }
            input[type=text], select {
              height: 46px;
            }
            
            textarea {
              height: 95px;
            }
          }
        }
      }
    }
    .checkbox-group {
      padding-left: 5px;
      .form-check {
        margin-bottom: 20px;
        &:last-child{
          //margin-bottom: 0;
        }
        .form-check-label {
          display: inline-block;
          padding-left: 9px;
          font-size: 15px;
          line-height: 20px;
          z-index: 1000;
          text-align: left;
          @media screen and (max-width: 575px){
            padding-left: 33px;
          }
        }
      }
    }
  }


  &.theme-dark, &.dark {
    background: $color-primary;

    .section-title, .form-subheading {
    }

    .nav-tabs {
      .nav-tab-item, .nav-item {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        .nav-tab-link, .nav-link {
          position: relative;
          // left: 100%;
          // opacity: 0;
        }
        &.active {
          background-color: $color-primary;
        }

      }
    }

    .tab-content {
      .tab-pane {
        form {
          .form-row {
            .form-group {
              input[type=text], input[type=tel], input[type=email], select, textarea {
                -webkit-appearance: none;
                background-color: $color-black;
                border: solid 2px $color-white;

                ::placeholder {
                  color: $color-gray;
                  opacity: 1;
                }
                :-ms-input-placeholder {
                  color: $color-gray;
                }
                ::-moz-placeholder {
                  color: $color-gray;
                }
                ::-webkit-input-placeholder {
                  color: $color-gray;
                }
              }

            }
          }
        }
      }
    }
  }

}

.form-select {
  background-color: $color-white;
  position: relative;
  width: 100%;
  &::after {
    content: '';
    display: block;
    width: 15px;
    height: 15px;
    border:2px solid $color-gray;
    border-top:none;
    border-left:none;
    transform: rotate(45deg);
    position:absolute;
    z-index: 1;
    top: 13px;
    right: 13px;
  }
  select {
    appearance: none;
    background:transparent;
    position: relative;
    z-index: 10;
    height: 46px;
    &:focus {
      background-color: transparent;
    }
  }
}

input[type=checkbox].css-checkbox /* Customize the label (the container) */
.form-check, .privacy-policy-check{
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.form-check input, .privacy-policy-check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 40px;
  width: 40px;
  z-index: 1000;
}

/* Create a custom checkbox */
.checkmark {
  position:absolute;
  top: 6px;
  @media screen and (max-width:$mobile){
    top: 0;
  }
  left: 6px;
  height: 27px;
  width: 27px;
  border: 1px solid $color-gray;
  background-color: $color-white;
  z-index: 0;
}
.privacy-policy-check .checkmark {
  left:10px;
  top: 0;
}
/* When the checkbox is checked, add a blue background */
.form-check input:checked ~ .checkmark {

}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  background-color: $color-primary;
  content: '';
  position: absolute;
  display: none;
  top: 3px;
  left: 3px;
  width: 19px;
  height: 19px;
}
/* Show the checkmark when checked */
.form-check input:checked ~ .checkmark:after, .privacy-policy-check input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.form-check .checkmark:after {

}

/* Focus state */
.form-check input:focus ~ .checkmark {
  outline: 5px auto -webkit-focus-ring-color;
}

/* Style the .form-check label */
.privacy-policy-check label  {
  // make label cover custom checkbox so that clicking on custom checkbox triggers checked state
  padding-left: 35px; // width of custom checkbox + 18px
}

/* Form success message popup styles */

input:-internal-autofill-selected {
  background-color: transparent !important;
}

label {
  &.error {
    position: absolute;
    color: $color-primary;
    font-size: 15px;
    bottom: -20px;

    @media screen and (max-width: $mobile) {
      position: static;
      font-size: 12px;
    }
  }
}
.form-band {
  label.error {
    color: white;
   position: static;
   margin-top: 5px;
  }
}
.privacy-policy-check {
  label.error {
    color: $color-primary;
   position: absolute;
   margin-top: 0;
  }
}
.hinNumber {
  width: 100%;
  margin-bottom: 20px;
  display: none;
  @media screen and (max-width: $mobile) {
    margin-bottom: 5px;
  }

  &.visible {
    display: block;
  }
  label {
    font-size: 12px;
    justify-content: flex-start;
    text-align: left;
    padding: 10px 15px;
  }
}

/*
.dynamic-row {
  &.visible {
    .form-group.email {
      margin-bottom: 20px;

      @media screen and (max-width: $mobile) {
        margin-bottom: 0px;
      }
    }
  }
  .form-group {
    &.address {
      margin-bottom: 20px;
      @media screen and (max-width: $mobile) {
        margin-bottom: 0px;
      }
    }
    &.dynamic-address-fields {
      display: none!important;

      &.visible {
        display: inherit!important;
      }
    }
  }
}
*/

::placeholder {
  opacity: 1;
}


.contact-selectable {
  padding: 40px 0;
  border-bottom: $section-break;
  .section-title {
    margin-bottom: 0;
  }
}